import React, { Component } from 'react'
import {
  View,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
  Keyboard,
} from 'react-native'

import { HeightDimen } from '../../UI/Dimensions'
import { MainFlowHeaderContainer } from '../../Component/Header/MainFlowHeader'
import { TextButton } from '../../Component/Button/TextButton'
import interestCaller from '../../API/InterestCaller'
import myData from '../../Data/MyData'
import MyToast from '../../Component/Toast/MyToast'
import { RecoverTextInput } from '../../Component/Input/RecoverTextInput'
import { strings } from '../../Language/Language'
import { EnterTransactionPinModal } from '../../Component/Modal/EnterTransactionPinModal'

import MyData from '../../Data/MyData'
import { normalizeDecimalValue } from '../../Utils/Common'

class CreateInterestScreen extends Component {
  state = {
    isLoading: false,
    amount: '0.00',
    showPinModal: false,
    availableBalance: MyData?.temp_json_object?.response?.user?.walletBalance,
  }
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
  }

  onPress_forgot_pin = () => {
    this.setState({ showPinModal: false })
    this.props.navigation.navigate('RecoverPin')
  }

  onSuccEnterPin = () => {
    this.setState({ showPinModal: false })
    this.createInterest()
  }

  onBackModal = () => {
    this.setState({ showPinModal: false })
  }

  createInterest = async () => {
    this.setState({ isLoading: true })
    await interestCaller
      .createInterestAccount(myData.authToken, this.state.amount)
      .then((res) => {
        //console.log(res);

        this.props.route.params.onGoBack()
        this.props.navigation.goBack()
      })
      .catch((err) => {
        if (err.response.status == 400) {
          MyToast.popToast(err.response.data.msg)
        } else if (err.response.status == 514) {
          this.props.expiredToken_logout()
        }
        this.setState({ isLoading: false })
      })
  }

  handle_amount = (val) => {
    let value = normalizeDecimalValue(val)
    if (Number(value) <= Number(this.state.availableBalance)) {
      // this.inputRef.current.setSelection(value?.length, value?.length)
      this.setState({
        amount: value,
      })
    } else {
      // this.inputRef.current.setSelection(
      //   this.state.availableBalance?.length,
      //   this.state.availableBalance?.length
      // )
      this.setState({
        amount: this.state.availableBalance,
      })
    }
  }

  onNextPressed = () => {
    if (this.state.amount != '') {
      Keyboard.dismiss()
      this.setState({ showPinModal: true })
    } else {
      MyToast.popToast(strings.t('invalid_data_input'))
    }
  }

  onBackPressed = () => {
    this.props.navigation.goBack()
  }
  onFocus = () => {
    // this.inputRef.current.setSelection(
    //   this.state.amount?.length,
    //   this.state.amount?.length
    // )
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <EnterTransactionPinModal
          isShow={this.state.showPinModal}
          onPressForgotPin={() => this.onPress_forgot_pin()}
          onSuccEnterPin={() => this.onSuccEnterPin()}
          onBackModal={() => this.onBackModal()}
        />
        <MainFlowHeaderContainer
          title={strings.t('create_interest')}
          onBackPress={() => this.onBackPressed()}
        >
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
            }}
          >
            <View
              style={{
                marginTop: HeightDimen(40),
              }}
            >
              <RecoverTextInput
                ref={this.inputRef}
                title={`${strings.t('deposit_RM')} (${MyData.country_code})`}
                placeholder={'0.00'}
                top={true}
                bottom={true}
                isNumber={true}
                onChangeText={this.handle_amount}
                value={this.state.amount}
                onFocus={this.onFocus}
              />
              {/* <Text
                                style={{
                                    fontSize: fontDimen(13),
                                    color: FontSecondaryColor,
                                    marginTop: WidthDimen(20),
                                    textAlign: 'center'
                                }}
                            >{'Tnc xxx'}</Text> */}
            </View>
            <View
              style={{
                width: '100%',
                alignItems: 'center',
                marginTop: HeightDimen(30),
              }}
            >
              <TextButton
                label={strings.t('next')}
                size={5}
                onPress={() => this.onNextPressed()}
                isLoading={this.state.isLoading}
              />
            </View>
          </View>
        </MainFlowHeaderContainer>
      </View>
    )
  }
}

export default CreateInterestScreen
