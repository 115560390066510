import React, { Component } from 'react'
import { View } from 'react-native'
import { HeightDimen } from '../../UI/Dimensions'
import { LoginFlowHeaderContainer } from '../../Component/Header/LoginFlowHeader'
import { TextButton } from '../../Component/Button/TextButton'
import { RecoverTextInput } from '../../Component/Input/RecoverTextInput'
import MyData from '../../Data/MyData'
import APICaller from '../../API/APICaller'
import MyToast from '../../Component/Toast/MyToast'
import { strings } from '../../Language/Language'
class ChangeLoginPasswordScreen extends Component {
  state = {
    old_pass: '',
    new_pass: '',
    confirm_pass: '',

    text: 'something something something something something somethings omethingsome thingso mething ',
    isLoading: false,
  }

  handle_old_pass = (old_pass_txt) => {
    this.state.old_pass = old_pass_txt
  }

  handle_new_pass = (new_pass_txt) => {
    this.state.new_pass = new_pass_txt
  }

  handle_confirm_pass = (confirm_pass_txt) => {
    this.state.confirm_pass = confirm_pass_txt
  }

  onBackPressed = () => {
    this.props.navigation.goBack()
  }

  refresh = (data) => {
    this.props.navigation.goBack()
  }

  onNextPressed = async () => {
    if (
      this.state.otp != '' &&
      this.state.new_pass != '' &&
      this.state.confirm_pass != ''
    ) {
      if (
        this.state.new_pass == this.state.confirm_pass &&
        this.state.new_pass.length >= 8
      ) {
        this.setState({ isLoading: true })
        await APICaller.change_password(
          MyData.userToken,
          '',
          this.state.old_pass,
          this.state.new_pass,
          this.state.confirm_pass
        )
          .then((res) => {
            if (res?.status == 203) {
              MyToast.popToast(strings.t('old_password_not_match'))
            } else if (res?.status == 200) {
              this.props.navigation.navigate('SuccChangeLoginPassword', {
                onGoBack: (data) => {
                  this.refresh(data)
                },
              })
            }
            this.setState({ isLoading: false })
            //console.log(res.msg)
          })
          .catch((err) => {
            //console.log("Error: " + err)

            MyToast.popToast(err.response.data.msg)

            this.setState({ isLoading: false })
          })
      } else {
        MyToast.popToast(strings.t('invalid_password'))
      }
    } else {
      MyToast.popToast(strings.t('invalid_data_input'))
    }
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <LoginFlowHeaderContainer
          onBackPress={() => this.onBackPressed()}
          mainText={strings.t('change_login_password')}
          subText={strings.t('select_one_of_the_cre')}
        >
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
              paddingBottom: HeightDimen(30),
            }}
          >
            <View
              style={{
                marginTop: HeightDimen(40),
              }}
            >
              <RecoverTextInput
                title={strings.t('old_password')}
                placeholder={strings.t('old_password')}
                top={true}
                isSecure={true}
                onChangeText={this.handle_old_pass}
              />
              <View style={{ height: HeightDimen(3) }}></View>
              <RecoverTextInput
                title={strings.t('new_password')}
                placeholder={strings.t('new_password')}
                isSecure={true}
                onChangeText={this.handle_new_pass}
              />
              <View style={{ height: HeightDimen(3) }}></View>
              <RecoverTextInput
                title={strings.t('confirm_password')}
                placeholder={strings.t('confirm_password')}
                bottom={true}
                isSecure={true}
                onChangeText={this.handle_confirm_pass}
              />
            </View>

            <View
              style={{
                width: '100%',
                alignItems: 'center',
                marginTop: HeightDimen(40),
              }}
            >
              <TextButton
                label={strings.t('next')}
                size={5}
                onPress={() => this.onNextPressed()}
                isLoading={this.state.isLoading}
              />
            </View>
            {/* <View 
                            style={{
                                marginTop: HeightDimen(10)
                            }}
                        >
                            <Text
                                style={{
                                    color: FontSecondaryColor,
                                    fontSize: fontDimen(15),
                                    marginTop: HeightDimen(10)
                                }}
                            >{this.state.text}</Text>
                        </View> */}
          </View>
        </LoginFlowHeaderContainer>
      </View>
    )
  }
}

export default ChangeLoginPasswordScreen
