import React, { Component } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Dimensions,
  Keyboard,
} from 'react-native'
import Text from '../../Component/Text/MyText'
import { FontMainColor } from '../../UI/Color'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'
import { LoginFlowHeaderContainer } from '../../Component/Header/LoginFlowHeader'
import { NormalTextInput } from '../../Component/Input/NormalTextInput'
import { TextButton } from '../../Component/Button/TextButton'
import { PickBankModal } from '../../Component/Modal/PickBankModal'
import MyToast from '../../Component/Toast/MyToast'
import BankAPICaller from '../../API/BankAPICaller'
import MyData from '../../Data/MyData'
import { strings } from '../../Language/Language'
import { AgreeCheckBox } from '../../Component/CheckBox/AgreeCheckBox'
import { EnterTransactionPinModal } from '../../Component/Modal/EnterTransactionPinModal'
import { letterRegex, numberRegex } from '../../Utils/Common'

class AddBankScreen extends Component {
  state = {
    accName: '',
    accNo: '',

    // isChecked: false,
    isLoading: false,
    bankList: [],
    selectedBank: strings.t('bank'),
    selectedBankIndex: -1,

    showPickModal: false,

    isCheck: false,
    showPinModal: false,
  }

  componentDidMount() {
    this.setState({
      bankList: this.props.route.params.value,
    })
  }

  onBackPressed = () => {
    this.props.navigation.goBack()
  }

  handle_accName = (val) => {
    if (letterRegex(val)) {
      let findDigit = val?.match(/\d+/)
      if (findDigit) {
        if (findDigit[0]?.length <= 5) this.setState({ accName: val })
        else return
      } else this.setState({ accName: val })
    } else return
  }

  handle_accNo = (val) => {
    if (numberRegex(val)) this.setState({ accNo: val })
    else return
  }

  onSubmit = async () => {
    let payload = {
      acountName: this.state.accName,
      acountNo: this.state.accNo,
      bankId: this.state.selectedBankIndex,
    }

    if (
      this.state.accName != '' &&
      this.state.accNo != '' &&
      this.state.selectedBankIndex >= 0
    ) {
      Keyboard.dismiss()
      this.setState({ showPinModal: true })
    } else {
      MyToast.popToast(strings.t('invalid_data_input'))
    }
  }

  onSubmitNewAccount = async () => {
    let payload = {
      acountName: this.state.accName,
      acountNo: this.state.accNo,
      bankId: this.state.selectedBankIndex,
    }

    this.setState({ isLoading: true })
    await BankAPICaller.createBankAccounts(
      MyData.authToken,
      this.state.bankList[this.state.selectedBankIndex].bank_id,
      this.state.accNo,
      this.state.accName
    )
      .then((res) => {
        //console.log(res);

        // MyData.bankId = this.state.bankList[
        //   this.state.selectedBankIndex
        // ].bank_id;
        // MyData.bankName = this.state.accName;
        // MyData.bankAccountNo = this.state.accNo;
        this.setState({ isLoading: false })
        MyToast.popToast(strings.t('add_back_account_succ'))
        this.props.route.params.onGoBack(true)
        this.props.navigation.goBack()
      })
      .catch((err) => {
        //console.log(err);
        this.setState({ isLoading: false })
        if (err.response.staus == '514') {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }

  onPress_forgot_pin = () => {
    this.setState({ showPinModal: false })
    this.props.navigation.navigate('RecoverPin')
  }

  onSuccEnterPin = () => {
    this.setState({ showPinModal: false })
    this.onSubmitNewAccount()
  }

  onBackModalShowPin = () => {
    this.setState({ showPinModal: false })
  }

  onBackModal = () => {
    this.setState({ showPickModal: false })
  }

  onSelectBank = (index) => {
    this.setState({
      selectedBankIndex: index,
      selectedBank: this.state.bankList[index].bank_name,
      showPickModal: false,
    })
  }

  onShowModal = () => {
    this.setState({
      showPickModal: true,
    })
  }

  checkAgree = (val) => {
    this.setState({ isCheck: val })
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <EnterTransactionPinModal
          isShow={this.state.showPinModal}
          onPressForgotPin={() => this.onPress_forgot_pin()}
          onSuccEnterPin={() => this.onSuccEnterPin()}
          onBackModal={() => this.onBackModalShowPin()}
        />
        <PickBankModal
          isShow={this.state.showPickModal}
          list={this.state.bankList}
          onBackPressed={() => this.onBackModal()}
          onPress={this.onSelectBank}
        />
        <LoginFlowHeaderContainer
          onBackPress={() => this.onBackPressed()}
          mainText={strings.t('add_bank_account')}
          subText={strings.t('welcom_text')}
        >
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
            }}
          >
            <View
              style={{
                marginTop: HeightDimen(40),
              }}
            >
              <TouchableOpacity
                onPress={() => this.onShowModal()}
                style={{
                  width: '100%',
                  height: HeightDimen(50),
                  backgroundColor: '#EFF2F4',
                  borderRadius: HeightDimen(10),
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: HeightDimen(15),
                  marginBottom: HeightDimen(20),
                }}
              >
                <Text
                  numberOfLines={1}
                  style={{
                    flex: 1,
                    fontSize: fontDimen(15),
                    color:
                      this.state.selectedBankIndex >= 0
                        ? FontMainColor
                        : '#B8BABD',
                  }}
                >
                  {this.state.selectedBank}
                </Text>
              </TouchableOpacity>
              {/* <NormalTextInput
                                placeholder={'Bank'}
                                marginBottom={HeightDimen(20)}
                                hideImage={true}
                                onChangeText={this.handle_bank}
                            /> */}

              <NormalTextInput
                placeholder={strings.t('account_name')}
                marginBottom={HeightDimen(20)}
                hideImage={true}
                value={this.state.accName}
                onChangeText={this.handle_accName}
              />
              <NormalTextInput
                placeholder={strings.t('account_no')}
                marginBottom={HeightDimen(20)}
                hideImage={true}
                isNumber={true}
                value={this.state.accNo}
                onChangeText={this.handle_accNo}
              />
              <AgreeCheckBox
                value={this.state.isCheck}
                onCheck={this.checkAgree}
                label={strings.t('i_have_read_and_agree')}
              />
            </View>
            <View
              style={{
                marginTop: HeightDimen(30),
              }}
            >
              {this.state.isCheck && (
                <TextButton
                  label={strings.t('next')}
                  size={5}
                  onPress={() => this.onSubmit()}
                  isLoading={this.state.isLoading}
                />
              )}
            </View>
          </View>
        </LoginFlowHeaderContainer>
      </View>
    )
  }
}

export default AddBankScreen
