import React, { Component } from 'react'
import { View, StatusBar, Image, Modal, TouchableOpacity } from 'react-native'
import MainFooter from '../../Component/Footer/MainFooter'
import ContactScreen from '../../Screen/Contact/ContactScreen'
import ProfileScreen from '../../Screen/Profile/ProfileScreen'
import { QRModal } from '../../Component/Modal/QRModal'
import MyData from '../../Data/MyData'
import MyToast from '../../Component/Toast/MyToast'
import { check_camera_permission } from '../../Permission/Permission'
import { strings } from '../../Language/Language'
import MainScreen from '../Discover/MainScreen'
import HistoryScreen from '../History/HistoryScreen'
import { AnalyticEvents, logMessageAnalytics } from '../../Utils/Analytics'
import { StaggerIconButton } from '../../Component/Button/StaggerIconButton'
import { HeightDimen } from '../../UI/Dimensions'
import { TextButton } from '../../Component'
import storageData from '../../Data/StorageData'
import ProductsData from '../../Data/ProductsData'
import { Camera } from 'expo-camera'

class HomeScreen extends Component {
  state = {
    selected: 1,
    showQRCodeModal: false,
    type: '',
    tabSelection: 1,
    showNotiImageModal: MyData.popupImage?.length > 0 ? true : false,
    giftList: [
      {
        image: require('../../Resources/Images/reward.png'),
        onPress: () => {
          if (storageData.saved_authSession != '') {
            this.props.navigation.navigate('LuckytownCoin')
          } else {
            this.props.empty_auth_session_logout()
          }
        },
      },
      {
        image: require('../../Resources/Images/Chat/icon_2.png'),
        onPress: () => {
          if (storageData.saved_authSession != '') {
            this.props.navigation.navigate('GiftDetail')
          } else {
            this.props.empty_auth_session_logout()
          }
        },
      },
    ],
  }

  constructor(props) {
    super(props)
    this.contactRef = React.createRef()
    // this.mainScreentRef = React.createRef();
    this.footerRef = React.createRef()
    this.profileRef = React.createRef()
  }
  async componentDidMount() {
    // await this.configureUXCam();
    const isNotiExist = this.props.route.params
      ? this.props.route.params.isNotification
      : false
    if (isNotiExist) {
      this.footerRef.current.onSelect(2)
    }
    this.props.navigation.addListener('focus', () => {
      this.setState({})
    })
  }
  onRefreshContactNotification = async () => {
    if (this.contactRef.current) {
      this.contactRef.current.onRefresh()
    }
    if (this.profileRef.current) {
      await this.profileRef?.current?.onCountNotifications()
    }
  }

  onContactNotificationClick = () => {
    this.footerRef.current.onSelect(2)
    this.contactRef.current.onRefresh()
  }

  onRefreshWalletBalance = async () => {
    if (this.profileRef?.current) {
      await this.profileRef?.current?.get_profile_data()
    }
    if (this.mainScreentRef) {
      this.mainScreentRef?.get_walletBalance()
    }
  }
  goToProfilePage() {
    this.footerRef.current.onSelect(5)
  }
  closeModal = () => {
    this.setState({
      showQRCodeModal: !this.state.showQRCodeModal,
    })
  }

  renderNotificationImageModal() {
    return (
      <Modal
        animationType={'slide'}
        transparent={true}
        visible={this.state.showNotiImageModal}
        statusBarTranslucent
        onRequestClose={() => this.setState({ showNotiImageModal: false })}
      >
        <View
          style={{
            flex: 1,
            backgroundColor: 'rgba(0,0,0,0.5)',
            justifyContent: 'center',
          }}
        >
          <View
            style={{
              backgroundColor: 'white',
              padding: HeightDimen(10),
              marginHorizontal: HeightDimen(10),
              borderRadius: HeightDimen(10),
            }}
          >
            <TouchableOpacity
              onPress={() => {
                this.setState({ showNotiImageModal: false })
                this.props.navigation.navigate('BannerDetail', {
                  bannerUrl: MyData?.popupImage[0]?.redirect_url,
                })
              }}
              style={{
                height: HeightDimen(200),
                width: 'auto',
              }}
            >
              <Image
                style={{
                  resizeMode: 'contain',
                  height: '100%',
                  width: '100%',
                }}
                source={{
                  uri: MyData?.popupImage[0]?.image_url,
                }}
              />
            </TouchableOpacity>
            <View style={{ marginTop: HeightDimen(10) }}>
              <TextButton
                label={strings.cancel}
                size={5}
                onPress={() => {
                  this.setState({ showNotiImageModal: false })
                }}
              />
            </View>
          </View>
        </View>
      </Modal>
    )
  }
  onUpdate_selection = (selection) => {
    if (storageData.saved_authSession) {
      if (selection == 3) {
        this.setState({
          showQRCodeModal: !this.state.showQRCodeModal,
        })
      } else {
        this.setState({ selected: selection })
        ProductsData.selectedCategory = ''
      }
    } else {
      this.props.empty_auth_session_logout()
    }
  }

  onTransfer = async () => {
    if (MyData.temp_json_object?.response?.user?.wallet_pin_status == 'true') {
      const status = await Camera.requestCameraPermissionsAsync()

      if (status.status === 'granted') {
        this.setState({ showQRCodeModal: false })

        this.props.navigation.navigate('TransferCamera', {
          onGoBack: (data) => {
            this.refresh(data)
          },
        })
      } else {
        MyToast.popToast(strings.t('please_allow_camera_permission'))
      }
    } else {
      this.setState({ showQRCodeModal: false })
      this.props.navigation.navigate('CreatePinVerification', {
        page: 'TransferCamera',
        onGoBack: (data) => {
          this.refresh(data)
        },
      })
    }
  }
  refresh = (data) => {
    MyToast.popToast(strings.transfer_succ)
  }
  onShare = async (option) => {
    try {
      const result = await Share.share(option)

      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      alert(error.message)
    }
  }
  onTabChange(selection) {
    this.setState({ tabSelection: selection })
  }
  render() {
    return (
      <View
        style={{
          flex: 1,
        }}
      >
        <StatusBar translucent backgroundColor="transparent" />
        {this.state.showQRCodeModal && (
          <QRModal
            isShow={this.state.showQRCodeModal}
            onBackPress={() => this.closeModal()}
            username={MyData.member_username}
            onTransfer={() => this.onTransfer()}
          />
        )}

        {this.state.selected === 1 ? (
          <View style={{ flex: 1 }}>
            <MainScreen
              {...this.props}
              // ref={this.mainScreentRef}
              onRef={(ref) => (this.mainScreentRef = ref)}
              onTabChange={(sel) => this.onTabChange(sel)}
              state={this}
            />
            {this.state.tabSelection == 1 && storageData.saved_authSession && (
              <StaggerIconButton list={this.state.giftList} />
            )}
          </View>
        ) : this.state.selected === 2 ? (
          <ContactScreen ref={this.contactRef} {...this.props} />
        ) : this.state.selected === 4 ? (
          <HistoryScreen {...this.props} />
        ) : this.state.selected === 5 ? (
          <ProfileScreen ref={this.profileRef} {...this.props} />
        ) : (
          <></>
        )}

        {storageData.saved_authSession && (
          <MainFooter
            ref={this.footerRef}
            onUpdateSelection={this.onUpdate_selection}
          />
        )}
        {this.renderNotificationImageModal()}
      </View>
    )
  }
}

export default HomeScreen
