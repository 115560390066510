import React, { Component } from 'react'
import { View } from 'react-native'
import { HeightDimen } from '../../UI/Dimensions'
import { LoginFlowHeaderContainer } from '../../Component/Header/LoginFlowHeader'
import { TextButton } from '../../Component/Button/TextButton'
import { RecoverTextInput } from '../../Component/Input/RecoverTextInput'
import MyData from '../../Data/MyData'
import APICaller from '../../API/APICaller'
import MyToast from '../../Component/Toast/MyToast'
import { strings } from '../../Language/Language'

class ChangeNickNameScreen extends Component {
  state = {
    nickname: '',
    isLoading: false,
  }

  componentDidMount() {
    this.setState({
      nickname:
        (MyData.memner_nickname && String(MyData.memner_nickname).trim()) || '',
    })
  }

  handle_nickname = (val) => {
    this.setState({ nickname: val })
  }

  onBackPressed = () => {
    this.props.navigation.goBack()
  }

  onNextPressed = async () => {
    if (this.state.nickname != '') {
      this.set_new_nickname()
    } else {
      MyToast.popToast(strings.t('invalid_data_input'))
    }
  }

  set_new_nickname = async () => {
    this.setState({ isLoading: true })
    await APICaller.change_nickname(MyData.authToken, this.state.nickname)
      .then((res) => {
        MyData.memner_nickname = this.state.nickname
        MyToast.popToast(strings.t('nickname_change_succ'))
        //console.log(res);

        this.setState({ isLoading: false })
        this.props.route.params.onGoBack()
        this.props.navigation.goBack()
      })
      .catch((err) => {
        //console.log(err);

        this.setState({ isLoading: false })

        MyToast.popToast(err.response.data.msg)
      })
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <LoginFlowHeaderContainer
          onBackPress={() => this.onBackPressed()}
          mainText={strings.t('change_nickname')}
          subText={strings.t('change_your_nickname')}
        >
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
              paddingBottom: HeightDimen(30),
            }}
          >
            <View
              style={{
                marginTop: HeightDimen(40),
              }}
            >
              <RecoverTextInput
                title={strings.t('nickname')}
                placeholder={strings.t('nickname')}
                top={true}
                bottom={true}
                onChangeText={this.handle_nickname}
                value={this.state.nickname}
              />
            </View>

            <View
              style={{
                width: '100%',
                alignItems: 'center',
                marginTop: HeightDimen(40),
              }}
            >
              <TextButton
                label={strings.t('next')}
                size={5}
                onPress={() => this.onNextPressed()}
                isLoading={this.state.isLoading}
              />
            </View>
            {/* <View 
                            style={{
                                marginTop: HeightDimen(10)
                            }}
                        >
                            <Text
                                style={{
                                    color: FontSecondaryColor,
                                    fontSize: fontDimen(15),
                                    marginTop: HeightDimen(10)
                                }}
                            >{this.state.text}</Text>
                        </View> */}
          </View>
        </LoginFlowHeaderContainer>
      </View>
    )
  }
}

export default ChangeNickNameScreen
