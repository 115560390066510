import MyData from '../Data/MyData'

const config = {
  //ROOT_URL: 'https://luckytown.club/Member/',
  ROOT_URL: '',
}

export const apiUrls = {
  register: `${config.ROOT_URL}user/registerNew`,
  otp: `${config.ROOT_URL}user/verification`,
  resendOtp: `${config.ROOT_URL}user/resendVerificationCode`,
  login: `${config.ROOT_URL}user/login`,
  recoverPassword: `${config.ROOT_URL}user/forgotPassword`,
  changePassword: `${config.ROOT_URL}user/changePassword`,
  walletBalance: `${config.ROOT_URL}user/walletBalance`,
  uploadReceiptImage: `${config.ROOT_URL}upload/topUpReceipt`,
  uploadTopUp: `${config.ROOT_URL}user/requestTopUp`,
  requestWithdraw: `${config.ROOT_URL}user/requestWithdraw`,
  getBankList: `${config.ROOT_URL}user/getBankList`,
  getTopUpBankList: `${config.ROOT_URL}user/getTopUpBankList`,
  addOrUpdateBankDetails: `${config.ROOT_URL}user/updateBankDetails`,
  getInterestAccount: `${config.ROOT_URL}interest/interestAccount`,
  openNewInterestAcctoun: `${config.ROOT_URL}interest/openInterestAccount`,
  cancelInterestAccount: `${config.ROOT_URL}interest/cancelInterestAccount`,
  partialWithdrawInterestAccount: `${config.ROOT_URL}interest/partialWithdraw`,
  getInterestHistory: `${config.ROOT_URL}user/interestTransaction`,
  getProductList: `${config.ROOT_URL}game/getProductList`,
  launchGame: `${config.ROOT_URL}game/launch`,
  getAvailableCredit: `${config.ROOT_URL}game/getAvailableCredit`,
  getBetHistory: `${config.ROOT_URL}game/getBetHistory`,
  closeGame: `${config.ROOT_URL}game/closeGame`,
  topUpHistory: `${config.ROOT_URL}user/topUpHistory`,
  withdrawHistory: `${config.ROOT_URL}user/withdrawHistory`,
  walletTransaction: `${config.ROOT_URL}user/walletTransaction`,
  setPin: `${config.ROOT_URL}user/setNewPin`,
  changePin: `${config.ROOT_URL}user/changePin`,
  recoverPin: `${config.ROOT_URL}user/forgotPin`,
  checkMember: `${config.ROOT_URL}user/searchMember`,
  transferMember: `${config.ROOT_URL}user/transfer`,
  changeNickname: `${config.ROOT_URL}user/changeNickname`,
  getLeaderboard: `${config.ROOT_URL}leaderboard/getLeaderboards`,
  getLeaderboardRank: `${config.ROOT_URL}leaderboard/getLeaderboardRank`,
  getCoinHistory: `${config.ROOT_URL}user/coinTransaction`,
  allWalletTransaction: `${config.ROOT_URL}user/allWalletTransaction`,
  allGameTransaction: `${config.ROOT_URL}user/allGameTransaction`,
  avatarList: `${config.ROOT_URL}user/getAvatarList`,
  updateAvatar: `${config.ROOT_URL}user/updateAvatar`,
  availableWithdraw: `${config.ROOT_URL}user/withdrawAvailable`,
  cancelTopUp: `${config.ROOT_URL}user/cancelTopUp`,
  cancelWithdraw: `${config.ROOT_URL}user/cancelWithdraw`,
  getAnouncement: `${config.ROOT_URL}Announcement/list`,
  loadBalance: `${config.ROOT_URL}user/reload`,
  gameChangePassword: `${config.ROOT_URL}game/changePassword`,
  otpBind: `${config.ROOT_URL}user/otpBind`,
  bind: `${config.ROOT_URL}user/bind`,
  checkTopup: `${config.ROOT_URL}user/checkTopup`,
  checkWithdraw: `${config.ROOT_URL}user/checkWithdraw`,
  friends: `${config.ROOT_URL}friend/list`,
  addFriend: `${config.ROOT_URL}friend/add`,
  editFriend: `${config.ROOT_URL}friend/editName`,
  deleteFriend: `${config.ROOT_URL}friend/delete`,
  searchFriend: `${config.ROOT_URL}friend/search`,
  changePasswordNoAuth: `${config.ROOT_URL}user/changePasswordNoAuth`,
  URL: `${config.ROOT_URL}User/getUrl`,
  getXe88GameList: `${config.ROOT_URL}game/getXe88List`,
  getPlayTechGameList: `${config.ROOT_URL}game/getPlayTechGameList`,
  launchXe88Game: `${config.ROOT_URL}game/launchXe88Game`,
  launchPlayTechGame: `${config.ROOT_URL}game/launchPlayTechGame`,
  getVPowerGameList: `${config.ROOT_URL}game/getVpowerListMobile`,
  launchVpowerGame: `${config.ROOT_URL}game/launchVpowerGame`,
  getWalletOTP: `${config.ROOT_URL}user/getWalletOtp`,
  sendRegisterOtp: `${config.ROOT_URL}user/sendRegisterOtp`,
  getJokerGameList: `${config.ROOT_URL}game/getJokerList`,
  launchJokerGame: `${config.ROOT_URL}game/launchJokerGame`,
  getTopupMethod: `${config.ROOT_URL}user/getTopupMethod`,
  notificationList: `notification/list`,
  notificationCount: `notification/count`,
  notificationViewed: `notification/viewed`,
  notificationDetail: `notification/getDetails`,
  notificationMarkAllasRead: 'notification/markAllAsRead',
  promoList: `Promo/list`,
  promoValidity: `Promo/check`,
  changeLanguage: `user/changeLanguage`,
  changePhone: `user/changePhone`,
  verifyChangePhone: `user/verifyChangePhone`,
  changePhoneOtp: `user/getChangePhoneOtp`,
  customAvatar: `upload/customAvatar`,
  recoverUsername: `user/forgotUsername`,
  multiAccountLogin: `user/multiAccountlogin`,
  verifyLoginToken: `user/verifyLoginToken`,
  forgotPasswordOtp: `user/sendForgotPasswordOtp`,
  getPragmaticGameList: `game/getPragmaticGameList`,
  launchPragmaticGame: `game/launchPragmaticGame`,
  getPlaytechSlotGameList: `game/getPlayTechSlotGameList`,
  launchPlaytechSlotGame: `game/launchPlayTechSlotGame`,
  currencyPreview: `currency/preview`,
  requestTopupUsdt: `topup/requestTopUpUsdt`,
  getPlatform: `currency/getPlatform`,
  rewardList: `reward/list`,
  rewardClaim: `reward/claim`,
  updateBankAccount: `bank/updateAccount`,
  createBankAccount: `bank/createAccount`,
  bankAccounts: 'bank/accounts',
  deleteBankAccounts: 'bank/deleteAccount',
  getFunHouseGameList: `game/getFunhouseList`,
  launchFunHouseGame: `game/launchFunhouseGame`,
  countryList: `country/list`,
  switchCountry: `country/switch`,
  currencyList: `currency/list`,
  currencyExchangePreview: `currency/exchangePreview`,
  currencyExchange: `currency/exchange`,
  latestTransactionList: `user/getLatestTransactions`,
  get100PlusGameList: `game/getProductGameList`,
  launch100PlusGame: `game/launchProductGame`,
  getQRTopupBankPay: `topup/getBankWithQRPay`,
  requestQRPayTopup: `topup/requestQRPayTopUp`,
  bannerList: `Banner/list`,
  getPGSGameList: `game/getPegasusGameList`,
  launchPGSGame: `game/launchPegasusGame`,
  previewithdrawUsdt: `withdraw/previewWithdrawUSDT`,
  getProfileData: `user/getProfileData`,
  countryListNoAuth: `country/listNoAuth`,
  getKingKongGameList: `game/getKingKongGameList`,
  launchKingKongGame: `game/launchKingKongGame`,
  getJDBFishGameList: `game/getSexyJDBFishGameList`,
  launchJDBFishGame: `game/launchSexyJDBFishGame`,
  getSpadeFishGameList: `game/getSexySpadeFishGameList`,
  launchSpadeFishGame: `game/launchSexySpadeFishGame`,
  getYLFishGameList: `game/getSexyYLFishGameList`,
  launchYLFishGame: `game/launchSexyYLFishGame`,
  getSexyPragmaticGameList: `Game/getSexyPragmaticPlayGameList`,
  launchSexyPragmaticGame: `Game/launchSexyPragmaticPlayGame`,
  getSexyTigerGameList: `Game/getSexyRedTigerGameList`,
  launchSexyTigerGame: `Game/launchSexyRedTigerGame`,
  getSexyPGGameList: `Game/getSexyPGGameList`,
  launchSexyPGGame: `Game/launchSexyPGGame`,
  getSexyJiliGameList: `Game/getSexyJiliGameList`,
  launchSexyJiliGame: `Game/launchSexyJiliGame`,
  selectedTopupBank: `user/getSelectedTopupBank`,
  saveSelectedTopupBank: `user/saveSelectedTopupBank`,
  cancelSelectedTopupBank: `user/cancelSelectedTopupBank`,
  getChatMessageList: `chat/getMessages`,
  sendChatMessage: `chat/sendMessage`,
  getChatList: `Chat/list`,
  couponList: `coupon/list`,
  redeemCoupon: `coupon/redeemCoupon`,
  allWallet: `wallet/list`,
  getKingMakerGameList: `Game/getSexyKingmakerGameList`,
  launchKingMakerGame: `Game/launchSexyKingmakerGame`,
  checkUsername: `user/checkUsername`,
  validateUsername: `user/validateUsername`,
  getCrowdPlayGameList: `game/getCrowdplayGameList`,
  launchCrowdPlayGame: `game/launchCrowdplayGame`,
  getNextSpinGameList: `game/getNextSpinGameList`,
  launchNextSpinGame: `game/launchNextSpinGame`,
  getAeSexyGameList: `game/getSexyAEGameList`,
  launchAeSexyGame: `game/launchSexyAEGame`,
  funchouseCloeGame: `game/closeFunhouse`,
  getAESlotGameList: `game/getAESlotGameList`,
  launchAESlotGame: `game/launchAESlotGame`,
  getOtp: `user/getOtp`,
  verifyOtp: `user/verifyOtp`,
  checkVerified: 'User/checkVerified',
  getLKYSlotGameList: `Game/getLkyGameList`,
  launchLKYSlotGame: `Game/launchLkyGame`,
  markFirstTimeLogin: `user/markFirstTime`,
  checkVerified: 'User/checkVerified',
  lkpointTransaction: 'Vip/LkpointTransaction',
  getLink: `gift/getLink`,
  pullOutCreditByProduct: `game/pullOutCreditByProduct`,
  pullOutAllCredit: `game/pullOutCreditAllProduct`,
  topUpReceiptBatch: `upload/topUpReceiptBatch`,

  previewWithdrawUSDT: `withdraw/previewUSDT`,
  submitWithdrawUSDT: `withdraw/submitUSDT`,
  getUSDTAvailableBalance: `withdraw/getUSDTAvailableBalance`,
  generateGoogleAuthQrcode: `user/generateGoogleAuthQrcode`,
  saveGoogleAuthSecret: `user/saveGoogleAuthSecret`,
  getLkyRedmeeLink: `vip/getLink`,
  removeGoogleAuthSecret: `user/removeGoogleAuthSecret`,
  getRealnameVerificationStatus: 'user/getRealnameVerificationStatus',
  requestRealNameCertification: 'user/requestRealNameCertification',
  bonusChip: 'cash/bonusChip',
  checkWalletPin: 'user/checkWalletPin',
  getUsernameByLink: 'user/getUsernameByLink',
  requestRegisterOtp: `user/requestRegisterOtp`,
  verifyRegisterOtp: `user/verifyRegisterOTP`,
  currencyPreviewRate: 'currency/previewrate',
}
