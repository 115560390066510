import React, { Component } from 'react'
import {
  View,
  FlatList,
  ActivityIndicator,
  Modal,
  Image,
  TouchableOpacity,
  TextInput,
  TouchableWithoutFeedback,
  Keyboard,
} from 'react-native'

import Text from '../../Component/Text/MyText'
import { fontDimen, HeightDimen, WidthDimen } from '../../UI/Dimensions'
import { NoBackHeaderContainer } from '../../Component/Header/NewDesign/NoBackHeader'
import { FriendCard } from '../../Component/Card/NewDesign/FriendCard'
import { strings } from '../../Language/Language'
import { NormalTextInput } from '../../Component/Input/NormalTextInput'
import ContactCaller from '../../API/ContactAPICaller'
import MyToast from '../../Component/Toast/MyToast'
import MyData from '../../Data/MyData'
import { FontSecondaryColor, MainColor, SecondaryColor } from '../../UI/Color'
import { TextButton } from '../../Component/Button/TextButton'
import transferAPICaller from '../../API/TransferAPICaller'
import { SearchBar } from '../../Component/SearchBar/SearchBar'
import { SuccessModal } from '../../Component/Modal/SuccessModal'
import { ProfileCard } from '../../Component/Card/NewDesign/ProfileCard'
import { Line } from '../../Component/Line/Line'
import { IconTextCard } from '../../Component/Card/NewDesign/IconTextCard'
// import {
//   AnalyticEvents,
//   logEventWithFriendName,
//   logMessageAnalytics,
//   logScreenViewAnalytics,
//   screenNames,
// } from '../../Utils/Analytics'
import { io } from 'socket.io-client'
class ContactScreen extends Component {
  state = {
    friendList: [],
    isLoading: false,
    selectedFriend: {},
    isFriendModalShow: false,
    tempFriendList: [],
    isAddFriendModalShow: false,
    friendName: '',
    searchUsername: '',
    isFriendLoading: false,
    isSuccessModal: false,
    isEditFriend: false,
  }

  async componentDidMount() {
    //logScreenViewAnalytics(screenNames.Friend)

    //  await this.configureSocketIO();
    await this.onGetFriendList()
  }
  configureSocketIO = async () => {
    const socket = io('https://ws.lkyhub.xyz/')
    socket.connect()
    socket.on('new-chat-message', (msg) => {
      if (msg) this.onGetFriendList(false)
    })
  }
  onGetFriendList = async (wantLoading = true) => {
    this.setState({ isLoading: wantLoading && true })
    await ContactCaller.getFriendList(MyData.authToken)
      .then((res) => {
        // logMessageAnalytics(AnalyticEvents.FriendsSuccess)
        console.log(res.response.list)
        let tempList = []
        res.response.list.map((item, index) => {
          if (item && item?.member_username)
            tempList.push({
              ...item,
              value: item.member_username,
              key: item.friend_id,
            })
        })

        this.setState({
          isLoading: false,
          friendList: tempList,
          tempFriendList: tempList,
        })
      })
      .catch((err) => {
        // logMessageAnalytics(
        //   AnalyticEvents.FriendsFailure,
        //   err.response.data.msg
        // )
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }
  onRefresh = () => {
    this.onGetFriendList()
  }
  onPress_transfer = (username) => {
    //   logEventWithFriendName(AnalyticEvents.TransferFriendClick, username)
    this.props.navigation.navigate('TransferManual', {
      userName: username,
      isContact: true,
      onGoBack: (data) => {
        this.onRefresh(data)
      },
    })
    this.setState({ isFriendModalShow: false })
  }

  onEditFriend = async (name, key) => {
    if (name) {
      this.setState({ isFriendLoading: true })
      await ContactCaller.editFriend(MyData.authToken, name, key)
        .then((res) => {
          // logMessageAnalytics(AnalyticEvents.EditFriendsSuccess)
          this.setState({
            isFriendModalShow: false,
            isEditFriend: false,
          })
          this.onGetFriendList()
        })
        .catch((err) => {
          // logMessageAnalytics(
          //   AnalyticEvents.EditFriendsFailure,
          //   err.response.data.msg
          // )
          if (err.response.status == 514) {
            this.props.expiredToken_logout()
          } else {
            MyToast.popToast(err.response.data.msg)
          }
        })
        .finally(() => {
          this.setState({ isFriendLoading: false })
        })
    } else {
      MyToast.popToast(strings.t('pleaseent'))
    }
  }

  onDeleteFriend = async (key) => {
    //  logMessageAnalytics(AnalyticEvents.DeleteFriendClick)
    this.setState({ isFriendLoading: true })
    await ContactCaller.deleteFriend(MyData.authToken, key)
      .then((res) => {
        // logMessageAnalytics(AnalyticEvents.DeleteFriendsSuccess)
        this.setState({
          isFriendModalShow: false,
        })
        this.onGetFriendList()
      })
      .catch((err) => {
        // logMessageAnalytics(
        //   AnalyticEvents.DeleteFriendsFailure,
        //   err.response.data.msg
        // )
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isFriendLoading: false })
      })
  }

  onHandleEdit = (key) => {
    const { friendName } = this.state
    //  logEventWithFriendName(AnalyticEvents.EditFriendClick, friendName)
    this.onEditFriend(friendName, key)
  }
  onPress_customerService = () => {
    //    logMessageAnalytics(AnalyticEvents.FriendCustomerServiceClick)
    this.props.navigation.navigate('CustomerService', {
      onGoBack: (data) => {
        this.onRefresh(data)
      },
    })
  }
  onAddFriend = async (friendKey) => {
    await ContactCaller.addFriend(MyData.authToken, friendKey)
      .then((res) => {
        //  logMessageAnalytics(AnalyticEvents.AddFriendsSuccess)
        this.setState({ isAddFriendModalShow: false, isSuccessModal: true })
        this.onGetFriendList()
      })
      .catch((err) => {
        // logMessageAnalytics(
        //   AnalyticEvents.AddFriendsFailure,
        //   err.response.data.msg
        // )
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isFriendLoading: false })
      })
  }

  onSearchFriend = async () => {
    if (this.state.searchUsername) {
      this.setState({ isFriendLoading: true })
      await ContactCaller.searchFriend(
        MyData.authToken,
        this.state.searchUsername
      )
        .then((res) => {
          //  logMessageAnalytics(AnalyticEvents.FriendSearchSuccess)
          this.onAddFriend(res.response.friend.member_unique_key)
        })
        .catch((err) => {
          // logMessageAnalytics(
          //   AnalyticEvents.FriendSearchFailure,
          //   err.response.data.msg
          // )
          if (err.response.status == 514) {
            this.props.expiredToken_logout()
          } else {
            MyToast.popToast(err.response.data.msg)
          }
        })
        .finally(() => {
          this.setState({ isFriendLoading: false })
        })
    } else {
      MyToast.popToast(strings.t('please_enter_friend_name'))
    }
  }

  onSearchTransferUsername = async () => {
    const { member_username } = this.state.selectedFriend
    // logEventWithFriendName(AnalyticEvents.TransferFriendClick, member_username)
    if (member_username) {
      this.setState({ isFriendLoading: true })
      await transferAPICaller
        .checkMember(MyData.authToken, member_username)
        .then((res) => {
          // logMessageAnalytics(AnalyticEvents.TransferFriendSearchSuccess)
          console.log(res)
          this.setState({
            isFriendLoading: false,
          })
          this.onPress_transfer(member_username)
        })
        .catch((err) => {
          // logMessageAnalytics(
          //   AnalyticEvents.TransferFriendSearchFailure,
          //   err.response.data.msg
          // )
          if (err.response.status == 400) {
            MyToast.popToast(strings.t('no_user_found'))
          } else if (err.response.status == 514) {
            this.props.expiredToken_logout()
          }

          this.setState({ isFriendLoading: false })
        })
    } else {
      MyToast.popToast(strings.t('please_enter_friend_name'))
    }
  }

  renderAlphabetList = () => {
    return (
      <FlatList
        style={{
          marginTop: HeightDimen(10),
          paddingHorizontal: HeightDimen(5),
        }}
        contentContainerStyle={{
          width: '100%',
        }}
        data={this.state.tempFriendList}
        onRefresh={() => this.onRefresh()}
        refreshing={false}
        renderItem={({ item, index }) => (
          <View style={{ marginHorizontal: 10 }}>
            <FriendCard
              onPress={() => {
                this.setState({
                  isFriendModalShow: true,
                  selectedFriend: item,
                  friendName: item.member_username,
                })
              }}
              name={item?.value}
              image={item?.member_avatar_url}
              count={item?.new_messages_count}
            />
            <Line marginTop={HeightDimen(8)} />
          </View>
        )}
        keyExtractor={(val, index) => String(index)}
      />
    )
  }
  addFriendModal = () => {
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={this.state.isAddFriendModalShow}
        statusBarTranslucent
      >
        {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}> */}
        <View style={styles.modalContainer}>
          <View style={styles.modalInnerContainer}>
            {/* <TouchableOpacity
                onPress={() => this.setState({ isAddFriendModalShow: false })}
                style={styles.closeButtonContainer}
              >
                <Image
                  source={require("../../Resources/Images/close-cross.png")}
                  style={styles.closeIcon}
                />
              </TouchableOpacity> */}
            <View style={styles.addFriendInputModalContainer}>
              <Text style={styles.addFriendLabel}>
                {strings.t('add_friend')}
              </Text>
              <NormalTextInput
                placeholder={strings.t('username_text')}
                onChangeText={(val) => this.setState({ searchUsername: val })}
                type={'username'}
              />
              {!this.state.isFriendLoading ? (
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginTop: HeightDimen(20),
                  }}
                >
                  <TextButton
                    onPress={() => {
                      this.onSearchFriend()
                    }}
                    label={strings.t('confirm')}
                    size={2.5}
                  />
                  <View style={{ marginLeft: HeightDimen(10) }}>
                    <TextButton
                      onPress={() => {
                        // logMessageAnalytics(
                        //   AnalyticEvents.AddFriendCancelClick
                        // )
                        this.setState({ isAddFriendModalShow: false })
                      }}
                      label={strings.t('cancel')}
                      blackBtn
                      size={2.5}
                    />
                  </View>
                </View>
              ) : (
                <View style={{ marginTop: HeightDimen(25) }}>
                  <ActivityIndicator size="small" color={MainColor} />
                </View>
              )}
            </View>
          </View>
        </View>
        {/* </TouchableWithoutFeedback> */}
      </Modal>
    )
  }

  renderFriendModal = () => {
    const { friend_unique_key, member_avatar_url, member_username } =
      this.state.selectedFriend
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={this.state.isFriendModalShow}
        statusBarTranslucent
      >
        {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}> */}
        <View style={styles.modalContainer}>
          <View style={styles.modalInnerContainer}>
            <TouchableOpacity
              onPress={() => {
                //   logMessageAnalytics(AnalyticEvents.edi)
                this.setState({
                  isFriendModalShow: false,
                  isEditFriend: false,
                })
              }}
              style={styles.closeButtonContainer}
            >
              <Image
                source={require('../../Resources/Images/close-cross.png')}
                style={styles.closeIcon}
              />
            </TouchableOpacity>
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: HeightDimen(20),
              }}
            >
              <View style={styles.avatarContainer}>
                <Image
                  style={styles.avatar}
                  source={{ uri: member_avatar_url }}
                />
              </View>
              {!this.state.isEditFriend && (
                <View style={styles.inputContainer}>
                  <Text style={styles.input}>{this.state.friendName}</Text>
                  <TouchableOpacity
                    onPress={() => this.setState({ isEditFriend: true })}
                    style={{
                      marginLeft: HeightDimen(10),
                    }}
                  >
                    <Image
                      source={require('../../Resources/Images/edit.png')}
                      style={{
                        tintColor: FontSecondaryColor,
                        width: 'auto',
                        height: HeightDimen(20),
                        aspectRatio: 1 / 1,
                        resizeMode: 'contain',
                      }}
                    />
                  </TouchableOpacity>
                </View>
              )}
              {this.state.isEditFriend && (
                <View style={styles.inputContainer}>
                  <NormalTextInput
                    placeholder={strings.t('friend_search_friend')}
                    onChangeText={(val) => this.setState({ friendName: val })}
                    type={'username'}
                    value={this.state.friendName}
                  />
                  <TouchableOpacity
                    onPress={() => this.onHandleEdit(friend_unique_key)}
                    disabled={this.state.isFriendLoading ? true : false}
                    style={{
                      backgroundColor: 'rgba(237, 181, 61, 0.2)',
                      padding: HeightDimen(15),
                      borderRadius: HeightDimen(10),

                      marginLeft: HeightDimen(5),
                    }}
                  >
                    <Image
                      source={require('../../Resources/Images/edit.png')}
                      style={{
                        tintColor: FontSecondaryColor,
                        width: 'auto',
                        height: HeightDimen(20),
                        aspectRatio: 1 / 1,
                        resizeMode: 'contain',
                      }}
                    />
                  </TouchableOpacity>
                </View>
              )}
              {!this.state.isFriendLoading ? (
                <View style={styles.button}>
                  <IconTextCard
                    src={require('../../Resources/Images/Footer/icon_chat.png')}
                    name={strings.t('message')}
                    onPress={() => {
                      // logEventWithFriendName(
                      //   AnalyticEvents.ChatFriendClick,
                      //   this.state.friendName
                      // )
                      this.setState({ isFriendModalShow: false })
                      this.props.navigation.navigate('ContactChat', {
                        onGoBack: (data) => {
                          this.onRefresh(data)
                        },
                        selectedFriend: this.state.selectedFriend,
                      })
                    }}
                    marginRight={HeightDimen(15)}
                    // disabled={true}
                  />
                  <IconTextCard
                    src={require('../../Resources/Images/Profile/icon_transfer.png')}
                    name={strings.t('transfer')}
                    hideShowNext
                    onPress={() => this.onSearchTransferUsername()}
                    marginRight={HeightDimen(15)}
                  />
                  <IconTextCard
                    src={require('../../Resources/Images/bin.png')}
                    name={strings.t('friend_delete')}
                    hideShowNext
                    onPress={() => this.onDeleteFriend(friend_unique_key)}
                  />
                </View>
              ) : (
                <View style={{ marginTop: HeightDimen(25) }}>
                  <ActivityIndicator size="small" color={MainColor} />
                </View>
              )}
            </View>
          </View>
        </View>
        {/* </TouchableWithoutFeedback> */}
      </Modal>
    )
  }

  onSearch = (search) => {
    const { friendList } = this.state

    let filterFriends = friendList.filter((o) => {
      return (
        o?.member_username
          .toUpperCase()
          .replace(/ /g, '')
          .includes(search.toUpperCase().replace(/ /g, '')) ||
        (o?.friend_nickname &&
          o?.friend_nickname
            .toUpperCase()
            .replace(/ /g, '')
            .includes(search.toUpperCase().replace(/ /g, '')))
      )
    })
    this.setState({ tempFriendList: filterFriends })
  }

  render() {
    return (
      <View style={styles.container}>
        <NoBackHeaderContainer title={strings.t('friend')}>
          {this.state.isSuccessModal && (
            <SuccessModal
              isShow={this.state.isSuccessModal}
              isSuccess={true}
              message={strings.t('friend_added_successfully')}
              onConfirmPress={() => {
                this.setState({ isSuccessModal: false })
              }}
            />
          )}
          <View style={styles.innerContainer}>
            <View style={styles.searchContainer}>
              <SearchBar
                placeholder={strings.t('search_friend')}
                onChangeText={(val) => this.onSearch(val)}
              />
              <View style={{ marginTop: HeightDimen(5) }}>
                <ProfileCard
                  src={require('../../Resources/Images/no-user.png')}
                  label={strings.t('add_friend')}
                  wantSecondaryLabel
                  onPress={() => {
                    // logMessageAnalytics(AnalyticEvents.AddFriendClick)
                    this.setState({ isAddFriendModalShow: true })
                  }}
                />
                <Line marginTop={HeightDimen(8)} />
                <ProfileCard
                  src={require('../../Resources/Images/Profile/icon_contact.png')}
                  label={strings.t('customer_service')}
                  wantSecondaryLabel
                  onPress={() => this.onPress_customerService()}
                />
                {/* <Line marginTop={HeightDimen(8)} />
                <ProfileCard
                  src={require("../../Resources/Images/icon_chatuser.png")}
                  label={"Add Referal"}
                  hideShowNext
                  wantSecondaryLabel
                  onPress={() => this.onPress_bacnAcc()}
                /> */}
              </View>
            </View>
            {this.state.isLoading ? (
              <View style={{ marginTop: HeightDimen(15) }}>
                <ActivityIndicator size="small" color={MainColor} />
              </View>
            ) : (
              this.renderAlphabetList()
            )}
          </View>
        </NoBackHeaderContainer>
        {this.renderFriendModal()}
        {this.addFriendModal()}
      </View>
    )
  }
}
const styles = {
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  innerContainer: {
    flex: 1,
    paddingTop: HeightDimen(20),
  },
  searchContainer: {
    paddingHorizontal: HeightDimen(20),
    marginBottom: HeightDimen(10),
  },
  listContainer: {
    paddingHorizontal: HeightDimen(20),
    paddingVertical: HeightDimen(15),
  },
  modalContainer: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalInnerContainer: {
    backgroundColor: 'white',
    marginHorizontal: HeightDimen(20),
    borderRadius: HeightDimen(10),
    // width: '50%',
  },
  avatarContainer: {
    width: 'auto',
    height: HeightDimen(120),
    aspectRatio: 1 / 1,
    borderRadius: HeightDimen(5),
    borderWidth: HeightDimen(3),
    borderColor: SecondaryColor,
    backgroundColor: 'white',
    zIndex: 99,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    width: 'auto',
    height: HeightDimen(120),
    aspectRatio: 1 / 1,
    resizeMode: 'contain',
    borderRadius: HeightDimen(5),
  },
  inputContainer: {
    paddingHorizontal: WidthDimen(40),
    marginTop: HeightDimen(15),
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    fontSize: fontDimen(18),
    color: FontSecondaryColor,
    textAlign: 'center',
  },
  button: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: HeightDimen(20),
    width: '100%',
  },
  addFriendLabel: {
    fontSize: fontDimen(25),
    marginBottom: HeightDimen(20),
    fontWeight: 'bold',
    color: FontSecondaryColor,
    textAlign: 'center',
  },
  addFriendInputModalContainer: {
    padding: HeightDimen(30),
  },
  closeButtonContainer: {
    marginTop: HeightDimen(20),
    backgroundColor: 'gray',
    width: HeightDimen(35),

    height: HeightDimen(35),
    marginLeft: HeightDimen(15),
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: HeightDimen(22),
  },
  closeIcon: {
    width: 'auto',
    height: HeightDimen(15),
    aspectRatio: 1 / 1,
    resizeMode: 'contain',
    tintColor: 'white',
  },
}
export default ContactScreen
