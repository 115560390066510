import React, { Component } from 'react'
import { View } from 'react-native'
import { HeightDimen } from '../../UI/Dimensions'
import { LoginFlowHeaderContainer } from '../../Component/Header/LoginFlowHeader'
import { TextButton } from '../../Component/Button/TextButton'
import { RecoverTextInput } from '../../Component/Input/RecoverTextInput'
import MyData from '../../Data/MyData'
import APICaller from '../../API/APICaller'
import MyToast from '../../Component/Toast/MyToast'
import { strings } from '../../Language/Language'
import { EnterPhoneOtpPinModal } from '../../Component/Modal/EnterPhoneOtpPinModal'
import { PhoneTextInput } from '../../Component/Input/PhoneTextInput'
import { phoneRegex } from '../../Utils/Common'

class ChangePhoneNumberScreen extends Component {
  state = {
    phoneNumber: '',
    password: '',
    isLoading: false,
    showPinModal: false,
    code: '+60',
  }

  handle_phoneNo = (phone) => {
    this.setState({ phoneNumber: phone })
  }

  handle_pass = (password) => {
    this.state.password = password
  }

  onBackPressed = () => {
    this.props.navigation.goBack()
  }

  refresh = (data) => {
    this.props.navigation.goBack()
  }

  onNextPressed = async () => {
    const { password, phoneNumber, code } = this.state
    if (phoneNumber != '' && password != '') {
      this.setState({ isLoading: true })
      await APICaller.change_phone(
        MyData.authToken,
        phoneNumber,
        this.state.password,
        code
      )
        .then((res) => {
          this.setState({ isLoading: false, showPinModal: true })
        })
        .catch((err) => {
          if (err.response.status == 514) {
            this.props.expiredToken_logout()
          } else {
            MyToast.popToast(err.response.data.msg)
          }
          this.setState({ isLoading: false })
        })
    } else {
      MyToast.popToast(strings.t('invalid_password'))
    }
  }
  onBackModal = () => {
    this.setState({ showPinModal: false })
  }
  async onSuccessChangePhone(otp) {
    const { password, phoneNumber, code } = this.state
    await APICaller.verify_change_phone(
      MyData.authToken,
      phoneNumber,
      password,
      otp,
      code
    )
      .then((res) => {
        if (res.status == 200) {
          MyToast.popToast(strings.t('congratulations_changed_phone'))
          this.onBackPressed()
          this.onBackModal()
        } else {
          MyToast.popToast(res.data.msg)
        }
      })
      .catch((err) => {
        MyToast.popToast(err.response.data.msg)
      })
  }

  onGetOTP = async () => {
    const { password, phoneNumber, code } = this.state
    await APICaller.change_phone_otp(
      MyData.authToken,
      phoneNumber,
      password,
      code
    )
      .then((res) => {})
      .catch((err) => {
        MyToast.popToast(err.response.data.msg)
      })
  }

  onSelectCode(code) {
    this.setState({
      code: code,
    })
  }
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <EnterPhoneOtpPinModal
          isShow={this.state.showPinModal}
          onPressGetOtp={() => this.onGetOTP()}
          onSuccEnterPin={(val) => this.onSuccessChangePhone(val)}
          onBackModal={() => this.onBackModal()}
        />

        <LoginFlowHeaderContainer
          onBackPress={() => this.onBackPressed()}
          mainText={strings.t('change_phone_no')}
          subText={strings.t('change_your_phone')}
        >
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
              paddingBottom: HeightDimen(30),
            }}
          >
            <View
              style={{
                marginTop: HeightDimen(40),
              }}
            >
              <PhoneTextInput
                placeholder={strings.t('phone_number')}
                onSelectCodePress={(val) => this.onSelectCode(val)}
                top
                value={this.state.phoneNumber}
                isNumber={true}
                onChangeText={this.handle_phoneNo}
              />
              <View style={{ height: HeightDimen(3) }}></View>
              <RecoverTextInput
                title={strings.t('password_text')}
                placeholder={strings.t('password_text')}
                bottom={true}
                isSecure={true}
                onChangeText={this.handle_pass}
              />
            </View>

            <View
              style={{
                width: '100%',
                alignItems: 'center',
                marginTop: HeightDimen(40),
              }}
            >
              <TextButton
                label={strings.t('next')}
                size={5}
                onPress={() => this.onNextPressed()}
                isLoading={this.state.isLoading}
              />
            </View>
          </View>
        </LoginFlowHeaderContainer>
      </View>
    )
  }
}

export default ChangePhoneNumberScreen
