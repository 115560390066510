import React from 'react'
import { View, TouchableOpacity, Image } from 'react-native'
import Text from '../../Text/MyText'
import { SecondaryColor, FontSecondaryColor } from '../../../UI/Color'
import { HeightDimen, fontDimen } from '../../../UI/Dimensions'

const FriendCard = (props) => {
  const {
    top,
    bottom,
    hideShowNext,
    onPress,
    name,
    image,
    onDeletePress,
    count,
  } = props

  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        width: '100%',
        flexDirection: 'row',
        paddingTop: HeightDimen(10),
        alignItems: 'center',
      }}
    >
      <View
        style={{
          width: 'auto',
          height: HeightDimen(45),
          aspectRatio: 1 / 1,
          borderRadius: 9999,
          borderWidth: HeightDimen(3),
          borderColor: SecondaryColor,
          backgroundColor: 'white',
          zIndex: 99,
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image
          style={{
            width: 'auto',
            height: HeightDimen(40),
            aspectRatio: 1 / 1,
            resizeMode: 'contain',
          }}
          source={{ uri: image }}
        />
      </View>
      <View
        style={{
          flex: 1,
          marginLeft: HeightDimen(15),
          marginRight: HeightDimen(15),
        }}
      >
        <Text
          style={{
            fontSize: fontDimen(15),
            color: FontSecondaryColor,
            fontWeight: 'bold',
          }}
        >
          {name}
        </Text>
      </View>
      {count > 0 && (
        <View
          style={{
            height: 22,
            width: 22,
            backgroundColor: SecondaryColor,
            borderRadius: 11,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text
            style={{
              fontSize: fontDimen(11),
              color: 'white',
              fontWeight: 'bold',
            }}
          >
            {count}
          </Text>
        </View>
      )}
    </TouchableOpacity>
  )
}

export { FriendCard }
