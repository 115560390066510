import React, { Component } from 'react'
import {
  View,
  TouchableOpacity,
  ScrollView,
  ImageBackground,
  Dimensions,
  Image,
  Platform,
  StyleSheet,
  ActivityIndicator,
  RefreshControl,
  StatusBar,
  FlatList,
  SafeAreaView,
  Animated,
  Easing,
  Modal,
} from 'react-native'
import { NoBackHeaderContainerNoFeedback } from '../../Component/Header/NoBackHeader'
import { strings } from '../../Language/Language'
import DiscoverScreen from './DiscoverScreen'
import Text from '../../Component/Text/MyText'
import { fontDimen, HeightDimen, WidthDimen } from '../../UI/Dimensions'
import {
  FontMainColor,
  FontSecondaryColor,
  MainColor,
  SecondaryColor,
} from '../../UI/Color'
import LeaderScreen from './LeaderScreen'
import AnouncementScreen from './AnouncementScreen'
import PromotionScreen from './PromotionScreen'
import {
  getImageUrl,
  handle4_0_2_Check,
  handleNewDesignVersionCheck,
  handleOrientation,
  numberWithCommas,
  resetNavigationStack,
} from '../../Utils/Common'
import notificationCaller from '../../API/NotificationCaller'
import MyData from '../../Data/MyData'
import { CoinCard } from '../../Component/Card/CoinCard'
import walletAPICaller from '../../API/WalletAPICaller'
import { TopUpModal } from '../../Component/Modal/NewDesign/TopUpModal'
import MyToast from '../../Component/Toast/MyToast'
import leaderBoardCaller from '../../API/LeaderBoardCaller'

import bankAPICaller from '../../API/BankAPICaller'
const { width: screenWidth } = Dimensions.get('window')
import aPICaller from '../../API/APICaller'
import { TextButton } from '../../Component'
import storageData from '../../Data/StorageData'
import ProductsData from '../../Data/ProductsData'
import { walkthroughable, CopilotStep, copilot } from 'react-native-copilot'
import { CountryCodeModal } from '../../Component/Modal/CountryCodeModal'
import { ConfirmCancelModal } from '../../Component/Modal/ConfirmCancelModal'
import AsyncStorage from '@react-native-async-storage/async-storage'
let WalkthroughableView = walkthroughable(View)
let WalkthroughableBuutton = walkthroughable(TouchableOpacity)
const delay = (ms) => new Promise((res) => setTimeout(res, ms))

class MainScreen extends Component {
  state = {
    selection: 1,
    notificationCounter: 0,
    walletBalance: '0.00',
    coinBalance: '0.00',
    interesetBalance: '0.00',

    isLoading: true,
    topupList: [],
    show_top_up_modal: false,
    isTopupLoading: false,
    isWithdrawLoading: false,
    notch: false,
    bannerList: [],
    isVideoStart: false,
    bannerLoading: false,
    activeSlide: 0,
    curY: new Animated.Value(0),
    isDisabledQRTopup: false,
    opacity: 0,

    selectedCatIndex: 0,
    showComingSoonGameModal: false,
    isCountryModal: false,
    isCountryLoading: false,
    countryList: MyData.country_list,

    showAuthModal: false,
  }
  constructor(props) {
    super(props)
    this.discoverRef = React.createRef()
    this.announcementRef = React.createRef()
    this.leatherRef = React.createRef()
    this.scrollRef = React.createRef()
  }
  async componentDidMount() {
    // logScreenViewAnalytics(screenNames.Dashboard)
    this.props.onRef(this)
    await this.set_info()
    await this.getCountryList()
    await this.getTopupMethod()
    await this.getBannerList()
    await handleOrientation(false)
  }
  async markLoginFirstTime() {
    await aPICaller
      .markFirstTimeLogin(MyData.authToken)
      .then((res) => {
        MyData.isfirst_time_login = 'false'
      })
      .catch((err) => {
        if (err.response.status == '514') {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }
  componentWillUnmount() {
    this?.props?.copilotEvents?.off('stop')
  }

  renderCopilotSteps(step) {
    if (step == 2) {
      return (
        <CopilotStep
          text={strings.t('anoucement_intro_description')}
          order={step}
          name={strings.t('anouncement')}
        >
          <WalkthroughableBuutton>
            <TouchableOpacity
              onPress={() => {
                this.update_selection(3)
              }}
              style={{
                marginLeft: HeightDimen(5),
                paddingHorizontal: HeightDimen(10),
              }}
            >
              <Text
                style={{
                  fontSize: fontDimen(15),
                  color:
                    this.state.selection == 3 ? SecondaryColor : FontMainColor,
                }}
              >
                {strings.t('anouncement')}
              </Text>
            </TouchableOpacity>
          </WalkthroughableBuutton>
        </CopilotStep>
      )
    } else if (step == 3) {
      return (
        <CopilotStep
          text={strings.t('game_list_intro_description')}
          order={step}
          name={strings.t('game_list_intro_title')}
        >
          <WalkthroughableView>
            <DiscoverScreen
              {...this.props}
              ref={this.discoverRef}
              update_selection={this.update_selection}
              update_wallet_balance={this.onBalanceUpdate}
              update_category={(val) =>
                this.setState({ selectedCatIndex: val })
              }
              onAuth_Change={() => this.setState({ showAuthModal: true })}
              scrollEnabled={true}
            />
          </WalkthroughableView>
        </CopilotStep>
      )
    } else if (step == 1) {
      return (
        <CopilotStep
          text={strings.t('topup_intro_description')}
          order={step}
          name={strings.t('topup_intro_title')}
        >
          <WalkthroughableBuutton
            onPress={() => {
              //  logMessageAnalytics(AnalyticEvents.DashboardTopupClick)
              if (storageData.saved_authSession != '') {
                this.checkTopUp()
              } else {
                this.setState({ showAuthModal: true })
              }
            }}
            disabled={this.state.isTopupLoading}
            style={styles.container}
          >
            {/* <TouchableOpacity
              style={{ ...styles.container, backgroundColor: 'green' }}
              onPress={() => {
                //  logMessageAnalytics(AnalyticEvents.DashboardTopupClick)
                if (storageData.saved_authSession != '') {
                  this.checkTopUp()
                } else {
                  this.setState({ showAuthModal: true })
                }
              }}
              disabled={this.state.isTopupLoading}
            > */}
            {!this.state.isTopupLoading ? (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Image
                  source={require('../../Resources/Images/Profile/icon_topup.png')}
                  style={styles.icon}
                />
                <Text
                  style={{
                    color: MainColor,
                    fontSize: fontDimen(13),
                    marginLeft: HeightDimen(5),
                  }}
                >
                  {strings.t('top_up')}
                </Text>
              </View>
            ) : (
              <ActivityIndicator color={'white'} size={'small'} />
            )}
            {/* </TouchableOpacity> */}
          </WalkthroughableBuutton>
        </CopilotStep>
      )
    }
    // else if (step == 4) {
    //   return (
    //     <CopilotStep
    //       text={strings.leatherboard_intro_description}
    //       order={step}
    //       name={strings.leatherboard_intro_title}
    //     >
    //       <WalkthroughableBuutton>
    //         <TouchableOpacity
    //           onPress={() => this.update_selection(2)}
    //           style={{
    //             marginLeft: HeightDimen(5),
    //             paddingHorizontal: HeightDimen(10),
    //           }}
    //         >
    //           <Text
    //             style={{
    //               fontSize: fontDimen(15),
    //               color:
    //                 this.state.selection == 2 ? SecondaryColor : FontMainColor,
    //             }}
    //           >
    //             {strings.leaderboard}
    //           </Text>
    //         </TouchableOpacity>
    //       </WalkthroughableBuutton>
    //     </CopilotStep>
    //   );
    // }
    else if (step == 4) {
      return (
        <CopilotStep
          text={strings.t('topup_intro_description')}
          order={step}
          name={strings.t('promotion')}
        >
          <WalkthroughableBuutton>
            <TouchableOpacity
              onPress={() => {
                this.props.navigation.navigate('Coupon')
              }}
              style={{
                marginLeft: HeightDimen(5),
                paddingHorizontal: HeightDimen(10),
              }}
            >
              <Text
                style={{
                  fontSize: fontDimen(15),
                  color: FontMainColor,
                }}
              >
                {strings.t('promotion')}
              </Text>
            </TouchableOpacity>
          </WalkthroughableBuutton>
        </CopilotStep>
      )
    }
  }
  handleStepChange = (step) => {
    console.log(`Current step is: ${step}`)
    console.log(`Current step is: ${step.name}`)
  }

  set_info = async () => {
    if (!MyData.isfirst_time_login || MyData.isfirst_time_login == 'true') {
      // setTimeout(() => {
      //   this.props.start();
      // }, 3000);

      this.props?.copilotEvents?.on('stop', async () => {
        await this.markLoginFirstTime()
      })
    }
    this.setState({
      walletBalance:
        MyData?.temp_json_object?.response?.user?.walletBalance || '0.00',
      coinBalance:
        MyData?.temp_json_object?.response?.user?.coinBalance || '0.00',
      interesetBalance:
        MyData?.temp_json_object?.response?.user?.interestBalance || '0.00',

      isLoading: false,
    })
  }

  // getCountryList = async () => {
  //   await avatarCaller
  //     .getCountryList(MyData.authToken)
  //     .then(res => {
  //       this.setState({ countryList: res.response.list });
  //       MyData.country_list = res.response.list;
  //     })
  //     .catch(err => {
  //       if (err.response.status == "514") {
  //         this.props.expiredToken_logout();
  //       } else {
  //         MyToast.popToast(err.response.data.msg);
  //       }
  //     });
  // };
  getCountryList = async () => {
    if (storageData.saved_authSession == '') {
      this.setState({ bannerLoading: true })
      await aPICaller
        .getCountryListNoAuth()
        .then((res) => {
          this.setState({ countryList: res.response.list })
          MyData.country_url = res?.response?.list[0]?.icon_url
        })
        .catch((err) => {
          MyToast.popToast(err.response.data.msg)
        })
    }
  }
  update_selection = (sel) => {
    if (storageData.saved_authSession !== '') {
      if (this.state.selection != sel) {
        if (sel == 1) {
          // logMessageAnalytics(AnalyticEvents.DashboardGamesClick)
        } else if (sel == 2) {
          // logMessageAnalytics(AnalyticEvents.DashboardLeatherboardClick)
        } else if (sel == 3) {
          //   logMessageAnalytics(AnalyticEvents.DashboardAnouncementClick)
        }
        this.setState({ selection: sel })
        this.props.onTabChange(sel)
      }
    } else {
      this.setState({ showAuthModal: true })
    }
  }

  onCountNotifications = async () => {
    await notificationCaller
      .getNotifications(MyData.authToken)
      .then((res) => {
        // logMessageAnalytics(AnalyticEvents.CountNotificationSuccess)

        this.setState({
          notificationCounter: res.response.totalUnreadNotifications,
        })
        MyData.setNotifCount(res.response.totalUnreadNotifications)
      })
      .catch((err) => {
        // logMessageAnalytics(
        //   AnalyticEvents.CountNotificationFailure,
        //   err.response.data.msg
        // )
      })
  }

  get_walletBalance = async () => {
    await walletAPICaller
      .getWalletBalance(MyData.authToken)
      .then((res) => {
        //console.log(res.response);
        this.setState({
          walletBalance: res.response.walletBalance,
          coinBalance: res.response.coinBalance,
          interesetBalance: res.response.interestBalance,
        })
      })
      .catch((err) => {
        //console.log(err);

        MyToast.popToast(err.response.data.msg)
      })
  }
  onPress_topUp = () => {
    this.props.navigation.navigate('TopupWithdraw', {
      selectionType: 1,
      onGoBack: (data) => {
        // this.refresh(data);
      },
    })
  }
  checkTopUp = async () => {
    this.setState({ isTopupLoading: true })
    await walletAPICaller
      .onCheckTopUp(MyData.authToken)
      .then(async (res) => {
        if (res.status == 200) {
          await this.get_topup_QR_Pay()
          this.onPress_topUp()
        } else if (res.status == 201) {
          this.props.navigation.navigate('TopUpDetailsScreen', {
            bankList: [],
            selectedTopUpDetails: res.data.response.info,
            onGoBack: (data) => {
              //  this.on_Refresh(data);
            },
          })
        }
      })
      .catch((err) => {
        if (err.response.status == '514') {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isTopupLoading: false })
      })
  }
  get_topup_QR_Pay = async () => {
    await bankAPICaller
      .getQRTopup(MyData.authToken)
      .then((res) => {
        this.setState({ isDisabledQRTopup: false })
      })
      .catch((err) => {
        if (err.response.status == 400) {
          this.setState({ isDisabledQRTopup: true })
        }
      })
  }
  checkWithdraw = async () => {
    this.setState({ isWithdrawLoading: true })
    await walletAPICaller
      .onCheckWithdraw(MyData.authToken)
      .then((res) => {
        if (res.status == 200) {
          this.onPress_withdraw()
        } else if (res.status == 201) {
          this.props.navigation.navigate('WithdrawHistoryDetailsScreen', {
            bankList: [],
            selectedTopUpDetails: res.data.response.info,
            onGoBack: (data) => {
              //  this.refresh(data);
            },
          })
        }
      })
      .catch((err) => {
        if (err.response.status == '514') {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isWithdrawLoading: false })
      })
  }
  onPress_withdraw = () => {
    if (MyData.temp_json_object?.response?.user?.wallet_pin_status == 'true') {
      this.props.navigation.navigate('TopupWithdraw', {
        selectionType: 2,
        onGoBack: (data) => {
          // this.refresh(data);
        },
      })
    } else {
      this.props.navigation.navigate('CreatePinVerification', {
        page: 'Withdraw',
        onGoBack: (data) => {
          //this.refresh(data)
        },
      })
    }
  }
  onPress_qr_Topup = () => {
    this.setState({
      show_top_up_modal: false,
    })
    this.props.navigation.navigate('QRTopup', {
      onGoBack: (data) => {
        // this.refresh(data);
      },
    })
  }
  onPress_bacnAcc = () => {
    this.props.navigation.navigate('BankAcc', {
      onGoBack: (data) => {},
    })
  }
  getAvailableWithdraw = async () => {
    this.setState({ isWithdrawLoading: true, show_top_up_modal: false })

    await walletAPICaller
      .getAvailableWithdraw(MyData.authToken)
      .then((res) => {
        this.checkWithdraw()
      })
      .catch((err) => {
        if (err.response.status == '514') {
          this.props.expiredToken_logout()
        } else if (err.response.status == '303') {
          this.onPress_bacnAcc()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }
  getBannerList = async () => {
    this.setState({ bannerLoading: true })
    await leaderBoardCaller
      .getBannerList(MyData.authToken)
      .then((res) => {
        const baseModel = res.response.banners
        console.log(baseModel)
        // logMessageAnalytics(AnalyticEvents.BannerSuccess)
        const banners = baseModel.map((item) => {
          item.active = false
          return item
        })
        if (banners?.length > 0) {
          if (banners[0].banner_type === 'video') {
            banners[0].active = true
          }
        }
        this.setState({ bannerList: banners })

        // const hasVideo = baseModel.find((item) => {
        //   return item.banner_type == "video";
        // });
        // if (hasVideo) this.setState({ autoplay: false });
      })
      .catch((err) => {
        //  logMessageAnalytics(AnalyticEvents.BannerSuccess, err.response.data.msg)
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ bannerLoading: false })
        if (
          (storageData.saved_authSession != '' && !MyData.isfirst_time_login) ||
          MyData.isfirst_time_login == 'true'
        ) {
          setTimeout(() => {
            this?.props?.start()
          }, 2000)
        }
      })
  }
  getTopupMethod = async () => {
    this.setState({ bannerLoading: true })
    await walletAPICaller
      .getTopupMethods(MyData.authToken)
      .then((res) => {
        //  logMessageAnalytics(AnalyticEvents.TopupMethodSuccess)
        this.setState({ topupList: res.response.list })
      })
      .catch((err) => {
        // logMessageAnalytics(
        //   AnalyticEvents.TopupMethodFailure,
        //   err.response.data.msg
        // )
        this.setState({ bannerLoading: false })
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }
  renderTitleHeaderList = () => {
    return (
      <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
        <TouchableOpacity
          onPress={() => this.update_selection(1)}
          style={{
            marginLeft: HeightDimen(5),
            paddingHorizontal: HeightDimen(10),
          }}
        >
          <Text
            style={{
              fontSize: fontDimen(15),

              color: this.state.selection == 1 ? SecondaryColor : FontMainColor,
            }}
          >
            {strings.t('game')}
          </Text>
        </TouchableOpacity>

        {/* {MyData.directLine == "0" && (
          <>
            {this.state.version_4_0_2 ? (
              this.renderCopilotSteps(4)
            ) : (
              <TouchableOpacity
                onPress={() => this.update_selection(2)}
                style={{
                  marginLeft: HeightDimen(5),
                  paddingHorizontal: HeightDimen(10),
                }}
              >
                <Text
                  style={{
                    fontSize: fontDimen(15),
                    color:
                      this.state.selection == 2
                        ? SecondaryColor
                        : FontMainColor,
                  }}
                >
                  {strings.leaderboard}
                </Text>
              </TouchableOpacity>
            )}
          </>
        )} */}

        {this.renderCopilotSteps(2)}
        {this.renderCopilotSteps(4)}
        {ProductsData?.comingSoonGames?.length > 0 && (
          <TouchableOpacity
            onPress={() => this.setState({ showComingSoonGameModal: true })}
            style={{
              marginLeft: HeightDimen(5),
              paddingHorizontal: HeightDimen(10),
            }}
          >
            <Text
              style={{
                fontSize: fontDimen(15),
                color: FontMainColor,
              }}
            >
              {strings.t('coming_soon')}
            </Text>
          </TouchableOpacity>
        )}
      </ScrollView>
    )
  }
  onRefresh = () => {
    this.onCountNotifications()
  }

  renderFastImage = (item) => {
    return (
      <TouchableOpacity
        onPress={() => {
          this.props.navigation.navigate('BannerDetail', {
            bannerUrl: item?.redirect_url,
          })
        }}
        style={{
          height: HeightDimen(180),
        }}
      >
        <Image
          source={{
            uri: item.banner_url,
          }}
          style={{
            width: 'auto',
            height: '100%',
            aspectRatio: 1 / 1,
          }}
          resizeMode="center"
        />
      </TouchableOpacity>
    )
  }

  onEnd(e) {
    this.carousel?.snapToNext(true)
  }
  _renderItem = ({ item, index }) => {
    //const Video = require("react-native-video");

    return (
      <View style={styles.item}>
        {this.renderFastImage(item)}
        {/* {item.banner_type === "video" ? (
          <TouchableOpacity
            style={styles.item}
            onPress={() => {
              this.props.navigation.navigate("BannerDetail", {
                bannerUrl: item?.redirect_url,
              });
            }}
          >
            <Video.default
              ref={(ref) => {
                this.player = ref;
              }}
              source={{ uri: item.banner_url }}
              muted={true}
              paused={item.active ? false : true}
              controls={false}
              resizeMode="stretch"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
              }}
              onEnd={this.onEnd.bind(this)}
              onPlaybackStalled={() => {
                this.setState({ opacity: 1 });
              }}
              onPlaybackResume={() => {
                this.setState({ opacity: 0 });
              }}
              repeat={true}
            />
            <ActivityIndicator
              animating
              size="small"
              color={"white"}
              style={[
                styles.activityIndicator,
                { opacity: this.state.opacity },
              ]}
            />
          </TouchableOpacity>
        ) : (
          this.renderFastImage(item)
        )} */}
      </View>
    )
  }
  get_topup_QR_Pay = async () => {
    await bankAPICaller
      .getQRTopup(MyData.authToken)
      .then((res) => {
        this.setState({ isDisabledQRTopup: false })
      })
      .catch((err) => {
        if (err.response.status == 400) {
          this.setState({ isDisabledQRTopup: true })
        }
      })
  }
  onVideoStart = () => {
    this.setState({ isVideoStart: true })
  }
  onVideoEnd = () => {
    this.setState({ isVideoStart: false })
  }
  onPress_InstantTopUp = () => {
    this.setState({
      show_top_up_modal: false,
    })
    this.props.navigation.navigate('InstantTopUp', {
      onGoBack: (data) => {},
    })
  }
  onPress_UsdtTopUp = () => {
    this.setState({
      show_top_up_modal: false,
    })
    this.props.navigation.navigate('UsdtTopUp', {
      onGoBack: (data) => {},
    })
  }
  onPress_BankTopUp = () => {
    this.setState({
      show_top_up_modal: false,
    })
    this.props.navigation.navigate('TopUp', {
      onGoBack: (data) => {},
    })
  }
  go_interest = () => {
    this.props.navigation.navigate('Interest', {
      onGoBack: (data) => {},
    })
  }
  onCancel_modal = () => {
    this.setState({
      show_top_up_modal: false,
    })
  }
  _retrieveData = () => {
    const Carousel = require('react-native-snap-carousel')
    return (
      <>
        {!this.state.bannerLoading ? (
          <Carousel.default
            ref={(ref) => {
              this.carousel = ref
            }}
            sliderWidth={MyData.isMobile ? screenWidth : screenWidth * 0.6}
            sliderHeight={screenWidth}
            itemWidth={MyData.isMobile ? screenWidth : screenWidth * 0.6}
            data={this.state.bannerList}
            renderItem={this._renderItem}
            // onSnapToItem={(slideIndex) => {
            //   console.log(this.state.bannerList[slideIndex].banner_type);
            //   if (this.state.bannerList[slideIndex]?.banner_type == "video") {
            //     this.carousel.stopAutoplay();
            //     // const newList = this.state.bannerList.map((item, index) => {
            //     //   if (index == slideIndex) item.active = true;
            //     //   else item.active = false;
            //     //   return item;
            //     // });
            //     // this.setState({
            //     //   bannerList: newList,
            //     // });
            //   } else {
            //     this.carousel.startAutoplay();
            //   }
            // }}
            loop={true}
            autoplay={true}
            autoplayInterval={3000}
          />
        ) : (
          <View style={{ height: HeightDimen(180), justifyContent: 'center' }}>
            <ActivityIndicator color={MainColor} size={'small'} />
          </View>
        )}
      </>
    )
  }
  renderCommingSoonModal() {
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={this.state.showComingSoonGameModal}
        statusBarTranslucent
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalInnerContainer}>
            <Text style={{ ...styles.label, fontSize: fontDimen(18) }}>
              {strings.t('coming_soon')}
            </Text>

            <FlatList
              data={ProductsData?.comingSoonGames}
              numColumns={1}
              style={{ marginVertical: HeightDimen(15) }}
              renderItem={({ item }) => {
                return (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: HeightDimen(10),
                    }}
                  >
                    <View style={styles.avatarContainer}>
                      <Image
                        style={styles.avatar}
                        source={
                          getImageUrl(item?.product_name)
                            ? getImageUrl(item?.product_name)
                            : { uri: item?.product_image_url }
                        }
                      />
                    </View>
                    <View style={styles.labelContainer}>
                      <Text style={styles.label}>
                        {item?.product_language_name}
                      </Text>
                    </View>
                  </View>
                )
              }}
              keyExtractor={(item) => String(item.product_id)}
            />
            <View style={{ width: '100%', paddingHorizontal: HeightDimen(40) }}>
              <TextButton
                label={strings.t('app_close')}
                size={5}
                onPress={() =>
                  this.setState({ showComingSoonGameModal: false })
                }
              />
            </View>
          </View>
        </View>
      </Modal>
    )
  }
  renderCategorHeader = () => {
    return (
      <FlatList
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        horizontal={true}
        data={this.discoverRef?.current?.state?.categoryArr}
        style={{ flexGrow: 0 }}
        renderItem={({ item, index }) => (
          <TouchableOpacity
            onPress={() => {
              this.discoverRef?.current?.onCategoryPress(item, index)
              this.setState({ selectedCatIndex: index })
            }}
            style={{
              paddingLeft: HeightDimen(10),
              paddingRight: HeightDimen(10),
              paddingTop: HeightDimen(5),
              paddingBottom: HeightDimen(5),
              backgroundColor:
                this.state?.selectedCatIndex == index
                  ? SecondaryColor
                  : 'rgba(237, 181, 61, 0.1)',
              marginRight: HeightDimen(10),
              marginLeft: index == 0 ? HeightDimen(10) : 0,
              borderRadius: HeightDimen(9999),
            }}
          >
            <Text
              style={{
                fontSize: fontDimen(12),
                color: 'white',
              }}
            >
              {item}
            </Text>
          </TouchableOpacity>
        )}
        keyExtractor={(item) => String(item)}
      />
    )
  }
  onBalanceUpdate = (wallet) => {
    let walletBalance = wallet?.walletBalance
      ? wallet.walletBalance
      : MyData?.temp_json_object?.response?.user?.walletBalance || 0.0
    let coinBalance = wallet?.coinBalance
      ? wallet?.coinBalance
      : MyData?.temp_json_object?.response?.user?.coinBalance || 0.0
    let interestBalance = wallet?.interestBalance
      ? wallet?.interestBalance
      : MyData?.temp_json_object?.response?.user?.interestBalance || 0.0
    this.setState({
      walletBalance: walletBalance,
      coinBalance: coinBalance,
      interesetBalance: interestBalance,
    })
    if (storageData.saved_authSession != '') {
      MyData.temp_json_object.response.user.walletBalance = walletBalance
      MyData.temp_json_object.response.user.coinBalance = coinBalance
      MyData.temp_json_object.response.user.interestBalance = interestBalance
    }
  }

  onPress_Promotion_Topup = () => {
    this.setState({
      show_top_up_modal: false,
    })
    this.props.navigation.navigate('Coupon', {
      onGoBack: (data) => {},
    })
  }
  switchCountry = async (code, countryName, countryUrl, countryId) => {
    MyData.country_url = countryUrl
    MyData.country_name = countryName
    MyData.country_code = code
    MyData.country_id = countryId
    await AsyncStorage.setItem('countryCode', code)
    this.setState({ isCountryModal: false })
    await this.discoverRef?.current?.on_Refresh()
    await this.getBannerList()
  }
  render() {
    const { coinBalance, interesetBalance, walletBalance, isLoading } =
      this.state
    const headerDistance = Animated.diffClamp(
      this.state.curY,
      0,
      140
    ).interpolate({
      inputRange: [50, 100],
      outputRange: [-2000, 0],
      extrapolate: 'clamp',
    })
    if (!isLoading) {
      return (
        <View
          style={{
            flex: 1,
            // width: '100%',
            //  maxWidth: responsiveScreenWidth(70),
          }}
        >
          <Image
            source={require('../../Resources/Images/bg.png')}
            style={styles.backgroundImage}
          />
          <SafeAreaView style={{ flex: 1 }}>
            <StatusBar translucent backgroundColor={MainColor} />
            {this.state.topupList.length > 0 && (
              <TopUpModal
                isShow={this.state.show_top_up_modal}
                topupList={this.state.topupList}
                onPressInstantTopUp={() => this.onPress_InstantTopUp()}
                onPressBankTopUp={() => this.onPress_BankTopUp()}
                onPressUsdtTopUp={() => this.onPress_UsdtTopUp()}
                onBackPress={() => this.onCancel_modal()}
                onPressQRTopup={() => this.onPress_qr_Topup()}
                onPressPromotionTopUp={() => this.onPress_Promotion_Topup()}
              />
            )}
            <CountryCodeModal
              isShow={this.state.isCountryModal}
              list={this.state.countryList}
              onPress={(item) => {
                this.switchCountry(
                  item.code,
                  item.name,
                  item.icon_url,
                  item.country_id
                )
              }}
              onBackPress={() => {
                this.setState({ isCountryModal: false })
              }}
            />
            <ConfirmCancelModal
              isShow={this.state.showAuthModal}
              textResource={strings.t('please_login_first')}
              cancelLabel={strings.t('login_text')}
              confirmLabel={strings.t('signUp_text')}
              title={strings.t('login_text')}
              onConfirmPress={async () => {
                this.setState({ showAuthModal: false })
                await delay(100)
                this.props.empty_auth_session_logout(true)

                await delay(200)
                this.props.navigation.navigate('SignUp')
              }}
              onBackPress={async () => {
                this.setState({ showAuthModal: false })
                await delay(100)
                this.props.empty_auth_session_logout()
              }}
            />
            <View
              style={{
                flex: 1,
              }}
            >
              {this.discoverRef?.current?.state?.categoryArr?.length > 0 && (
                <Animated.View
                  style={{
                    transform: [
                      {
                        translateX: headerDistance,
                      },
                    ],
                    justifyContent: 'center',
                    height: HeightDimen(50),
                    // marginTop: HeightDimen(25),
                    elevation: 100,
                    zIndex: 100,
                    backgroundColor: MainColor,
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  {this.renderCategorHeader()}
                </Animated.View>
              )}
              <Animated.ScrollView
                style={{ flex: 1 }}
                ref={this.scrollRef}
                scrollEventThrottle={16}
                onScroll={Animated.event(
                  [
                    {
                      nativeEvent: { contentOffset: { y: this.state.curY } },
                    },
                  ],
                  { useNativeDriver: true }
                )}
                refreshControl={
                  <RefreshControl
                    refreshing={false}
                    onRefresh={() => {
                      if (this.state.selection == 1)
                        this.discoverRef?.current?.on_Refresh()
                      else if (this.state.selection == 2)
                        this.leatherRef?.current?.on_Refresh()
                      else if (this.state.selection == 3)
                        this.announcementRef?.current?.on_Refresh()
                    }}
                  />
                }
              >
                <View>{this._retrieveData()}</View>

                <View
                  style={{
                    flexDirection: 'row',
                    marginTop: HeightDimen(5),
                    paddingHorizontal: HeightDimen(10),
                    alignItems: 'center',
                  }}
                >
                  <CoinCard
                    title={strings.t('profile_cash')}
                    balance={`${MyData.country_code} ${numberWithCommas(
                      Number(walletBalance).toFixed(2)
                    )}`}
                    isDisable
                  />
                  {this.renderCopilotSteps(1)}
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    marginTop: HeightDimen(5),
                    paddingHorizontal: HeightDimen(10),
                    alignItems: 'center',
                  }}
                >
                  <CoinCard
                    title={strings.t('profile_chip')}
                    balance={`${MyData.country_code} ${numberWithCommas(
                      Number(coinBalance).toFixed(2)
                    )}`}
                    isDisable
                  />
                  <TouchableOpacity
                    style={styles.container}
                    onPress={() => {
                      if (storageData.saved_authSession != '') {
                        this.getAvailableWithdraw()
                      } else {
                        this.setState({ showAuthModal: true })
                      }
                    }}
                  >
                    {!this.state.isWithdrawLoading ? (
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Image
                          source={require('../../Resources/Images/Profile/icon_withdraw.png')}
                          style={styles.icon}
                        />
                        <Text
                          style={{
                            color: MainColor,
                            fontSize: fontDimen(13),
                            marginLeft: HeightDimen(5),
                          }}
                        >
                          {strings.t('withdraw')}
                        </Text>
                      </View>
                    ) : (
                      <ActivityIndicator color={'white'} size={'small'} />
                    )}
                  </TouchableOpacity>
                </View>

                <View style={{ flex: 1, marginTop: HeightDimen(10) }}>
                  <View
                    style={{
                      marginRight: HeightDimen(10),
                      marginBottom: HeightDimen(10),
                    }}
                  >
                    {this.renderTitleHeaderList()}
                  </View>

                  <View style={{ flex: 1, marginTop: HeightDimen(5) }}>
                    {this.state.selection == 1 ? (
                      this.renderCopilotSteps(3)
                    ) : this.state.selection == 2 ? (
                      <LeaderScreen
                        {...this.props}
                        noHeader={true}
                        ref={this.leatherRef}
                      />
                    ) : this.state.selection == 3 ? (
                      <AnouncementScreen
                        {...this.props}
                        ref={this.announcementRef}
                      />
                    ) : (
                      <PromotionScreen {...this.props} />
                    )}
                  </View>
                </View>
                {storageData.saved_authSession == '' && (
                  <TouchableOpacity
                    style={{
                      marginVertical: HeightDimen(5),
                      borderWidth: 3,
                      borderColor: SecondaryColor,
                      marginRight: HeightDimen(10),
                      position: 'absolute',
                      overflow: 'hidden',
                      right: 0,
                      top: 10,
                      borderRadius: 99999,
                    }}
                    onPress={() => {
                      if (this.state.countryList?.length == 0) return
                      this.setState({ isCountryModal: true })
                    }}
                  >
                    <Image
                      source={{ uri: MyData.country_url }}
                      style={styles.flag}
                    />
                  </TouchableOpacity>
                )}
              </Animated.ScrollView>
            </View>
            {this.renderCommingSoonModal()}
          </SafeAreaView>
        </View>
      )
    } else {
      return (
        <View style={{ flex: 1 }}>
          <ImageBackground
            source={require('../../Resources/Images/bg.png')}
            style={styles.backgroundImage}
          ></ImageBackground>
        </View>
      )
    }
  }
}
const styles = {
  backgroundImage: {
    position: 'absolute',
    width: '100%',

    height: '100%',
  },

  item: {
    // resizeMode: "cover",
    //  width: 'auto',
    // Without height undefined it won't work
    //height: HeightDimen(180),
    // figure out your image aspect ratio
    // aspectRatio: 1 / 1,
    backgroundColor: MainColor,

    overflow: 'hidden',
  },
  imageContainer: {
    flex: 1,
    marginBottom: Platform.select({ ios: 0, android: 1 }), // Prevent a random Android rendering issue
    backgroundColor: 'white',
    borderRadius: 8,
  },
  image: {
    ...StyleSheet.absoluteFillObject,
    resizeMode: 'contain',
  },
  container: {
    borderRadius: HeightDimen(10),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: SecondaryColor,
    width: '40%',
    padding: HeightDimen(9),
    marginLeft: HeightDimen(5),
  },
  icon: {
    height: HeightDimen(20),
    width: WidthDimen(17),
    resizeMode: 'contain',
    tintColor: MainColor,
  },

  modalContainer: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.5)',
    justifyContent: 'center',
  },
  modalInnerContainer: {
    backgroundColor: 'white',
    padding: HeightDimen(15),
    alignItems: 'center',
    borderRadius: HeightDimen(10),
    marginHorizontal: HeightDimen(20),
  },
  avatarContainer: {
    width: 'auto',
    height: HeightDimen(90),
    aspectRatio: 1 / 1,
    borderRadius: 9999,
    borderWidth: HeightDimen(3),
    borderColor: SecondaryColor,
    backgroundColor: 'white',
    zIndex: 99,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    width: 'auto',
    height: HeightDimen(90),
    aspectRatio: 1 / 1,
    resizeMode: 'contain',
  },
  label: {
    fontSize: fontDimen(14),
    fontWeight: 'bold',
    color: FontMainColor,
    marginLeft: HeightDimen(10),
  },
  labelContainer: {
    width: '70%',

    backgroundColor: 'rgba(237, 181, 61, 0.1)',
    borderTopRightRadius: HeightDimen(10),
    borderBottomLeftRadius: HeightDimen(10),
    borderBottomRightRadius: HeightDimen(10),
    justifyContent: 'center',
    padding: HeightDimen(20),
    marginLeft: -10,
  },
  steperLabels: {
    previous: strings.t('previous_step'),
    next: strings.t('next_step'),
    skip: strings.t('skip_step'),
    finish: strings.t('finish_step'),
  },
  tooltipStyle: {
    borderRadius: 10,
    paddingTop: 5,
  },
  flag: {
    width: 'auto',
    height: HeightDimen(50),
    aspectRatio: 1 / 1,
    // tintColor: "gray",
  },
}

const StepNumber = ({ currentStepNumber }) => (
  <View
    style={{
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: 2,
      borderRadius: 14,
      borderColor: '#FFFFFF',
      backgroundColor: SecondaryColor,
    }}
  >
    <Text>{currentStepNumber}</Text>
  </View>
)
export default copilot({
  labels: {
    previous: strings.t('previous_step'),
    next: strings.t('next_step'),
    skip: strings.t('skip_step'),
    finish: strings.t('finish_step'),
  },
  arrowColor: MainColor,
  tooltipStyle: {
    borderRadius: 10,
    paddingTop: 5,
  },
  stepNumberComponent: StepNumber,
  animated: true, // Can be true or false
  overlay: 'view', // Can be either view or svg
})(MainScreen)

// export function CommonHoc(WrappedComponent) {
//   const component = class extends React.Component {

//     render() {
//       debugger
//       return (
//         <>
//           <WrappedComponent ref="myComponent"  {...this.state} {...this.props} />
//         </>
//       );
//     }
//   };
//   return component;
// }
