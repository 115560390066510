import React, { Component } from 'react'
import { View, TouchableOpacity, Image, Platform } from 'react-native'
import { SecondaryColor } from '../../../UI/Color'
import { fontDimen, HeightDimen } from '../../../UI/Dimensions'
import Text from '../../Text/MyText'

const IconTextCard = (props) => {
  const { src, name, onPress, disabled, marginRight, customStyle, isLoading } =
    props

  return (
    <TouchableOpacity
      disabled={isLoading ? isLoading : disabled}
      style={{
        ...styles.container,
        backgroundColor: disabled ? 'gray' : SecondaryColor,
        marginRight: marginRight || 0,
      }}
      onPress={onPress}
    >
      <Image source={src} style={styles.image} />
      {name && <Text style={styles.label}>{name}</Text>}
    </TouchableOpacity>
  )
}
const styles = {
  container: {
    height: Platform.OS == 'ios' ? HeightDimen(60) : HeightDimen(70),
    padding: HeightDimen(5),
    borderRadius: HeightDimen(10),
    alignItems: 'center',
    justifyContent: 'center',
    width: '23%',
  },
  image: {
    width: 'auto',
    height: HeightDimen(20),
    aspectRatio: 1 / 1,
    resizeMode: 'contain',
    tintColor: 'white',
  },
  label: {
    color: 'white',
    fontSize: fontDimen(10),
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: HeightDimen(3),
  },
}
export { IconTextCard }
