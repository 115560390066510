import React from 'react'
import { View, TouchableOpacity, Image } from 'react-native'
import Text from '../Text/MyText'
import { FontMainColor, SecondaryColor } from '../../UI/Color'
import { HeightDimen, fontDimen, GetWidth } from '../../UI/Dimensions'
import { strings } from '../../Language/Language'
import moment from 'moment'
const cardWidthSize = (GetWidth - HeightDimen(20) * 2) / 3

const ProductCard = (props) => {
  const {
    onPress,
    image_url,
    disable,
    inGame,
    product_id,
    status,
    image_url_black_and_white,
    leader_image,
    product_image,
    product_image_black_and_white,
    date_from,
    date_to,
    maintenance_scheduled,
    product_name,
    product_coin_entitled,
  } = props

  return (
    <View
      onPress={onPress}
      style={{
        //  aspectRatio: 1 / 1.2,
        flexBasis: '25%',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',

        paddingTop: HeightDimen(4),
      }}
    >
      <TouchableOpacity
        onPress={onPress}
        disabled={disable}
        style={{
          // borderRadius: HeightDimen(10),
          overflow: 'hidden',
          width: '95%',
          // height: "75%",
          // borderWidth: HeightDimen(3),
          //borderColor: status == 'active' ? SecondaryColor : 'black',
        }}
      >
        {product_id == -11 ? (
          <Image
            style={{
              width: 'auto',
              height: 'auto',
              aspectRatio: 1 / 1,
              resizeMode: 'stretch',
            }}
            source={{ uri: leader_image }}
          />
        ) : (
          <Image
            style={{
              width: 'auto',
              height: 'auto',
              aspectRatio: 1 / 1,
              resizeMode: 'stretch',
            }}
            source={
              product_image
                ? status == 'active'
                  ? product_image
                  : product_image_black_and_white
                  ? product_image_black_and_white
                  : {
                      uri:
                        status == 'active'
                          ? image_url
                          : image_url_black_and_white,
                    }
                : {
                    uri:
                      status == 'active'
                        ? image_url
                        : image_url_black_and_white,
                  }
            }
          />
        )}
        <View
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            //  backgroundColor: 'transparent',
            //top: HeightDimen(14),
            //right: HeightDimen(3),
          }}
        >
          <Image
            source={require('../../Resources/Images/product_images/product-border.png')}
            style={{
              width: 'auto',
              height: '100%',
              aspectRatio: 1 / 1,
              //backgroundColor: 'transparent',
            }}
          />
        </View>
        {product_coin_entitled == 'true' && (
          <Image
            source={require('../../Resources/Images/product_images/coin.png')}
            style={{
              position: 'absolute',
              resizeMode: 'contain',
              height: HeightDimen(25),
              width: HeightDimen(25),
              top: HeightDimen(1),
              left: HeightDimen(0),
              zIndex: 999999,
            }}
          />
        )}
        {inGame && (
          <View
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: 'black',
              position: 'absolute',
              opacity: 0.7,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                fontSize: fontDimen(15),
                color: 'white',
              }}
            >
              {strings.t('in_game')}
            </Text>
          </View>
        )}
        {status != 'active' && (
          <View
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              justifyContent: 'flex-end',
            }}
          >
            <View
              style={{
                width: '100%',
                backgroundColor: 'black',
                paddingTop: HeightDimen(5),
                paddingBottom: HeightDimen(5),
                alignItems: 'center',
              }}
            >
              <Text
                style={{
                  fontSize: fontDimen(10),
                  color: 'white',
                }}
              >
                {status == 'maintenance' && maintenance_scheduled == '0' ? (
                  strings.t('maintenance')
                ) : status == 'maintenance' && maintenance_scheduled == '1' ? (
                  <View>
                    <Text
                      style={{
                        fontSize: fontDimen(10),
                        color: 'white',
                        textAlign: 'center',
                      }}
                    >
                      {strings.t('schedule')}
                    </Text>
                    <Text
                      style={{
                        fontSize: fontDimen(10),
                        color: 'white',
                        textAlign: 'center',
                      }}
                    >
                      {date_from}
                    </Text>
                    <Text
                      style={{
                        fontSize: fontDimen(10),
                        color: 'white',
                        textAlign: 'center',
                      }}
                    >
                      {date_to}
                    </Text>
                  </View>
                ) : (
                  strings.t('coming_soon')
                )}
              </Text>
            </View>
          </View>
        )}
      </TouchableOpacity>

      <View
        style={{
          marginTop: HeightDimen(4),
        }}
      />
      {/* <View
        style={{
          height: HeightDimen(30),
          marginTop: HeightDimen(2),
        }}
      >
        <Text
          style={{
            fontSize: fontDimen(10),
            color: FontMainColor,
            textAlign: 'center',
          }}
        >
          {product_name}
        </Text>
      </View> */}
    </View>
  )
}

export { ProductCard }
