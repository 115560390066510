import React, { Component } from 'react'
import { View, Keyboard } from 'react-native'

import { HeightDimen } from '../../UI/Dimensions'

import { MainFlowHeaderContainer } from '../../Component/Header/MainFlowHeader'
import { TextButton } from '../../Component/Button/TextButton'
import interestCaller from '../../API/InterestCaller'
import myData from '../../Data/MyData'
import MyToast from '../../Component/Toast/MyToast'
import { RecoverTextInput } from '../../Component/Input/RecoverTextInput'
import { strings } from '../../Language/Language'
import { EnterTransactionPinModal } from '../../Component/Modal/EnterTransactionPinModal'

import MyData from '../../Data/MyData'

class PartialWithdrawScreen extends Component {
  state = {
    isLoading: false,
    amount: '',
    key: '',
    showPinModal: false,
  }

  componentDidMount() {
    let temp = this.props.route.params.value
    this.setState({
      key: temp,
    })
    // console.log(temp)
  }

  onPress_forgot_pin = () => {
    this.setState({ showPinModal: false })
    this.props.navigation.navigate('RecoverPin')
  }

  onSuccEnterPin = () => {
    this.setState({ showPinModal: false })
    this.action_partialWithdraw()
  }

  onBackModal = () => {
    this.setState({ showPinModal: false })
  }

  action_partialWithdraw = async () => {
    this.setState({ isLoading: true })
    await interestCaller
      .partialWithdrawInterestAccount(
        myData.authToken,
        this.state.key,
        this.state.amount
      )
      .then((res) => {
        this.props.route.params.onGoBack(this.state.amount)
        this.props.navigation.goBack()
      })
      .catch((err) => {
        this.setState({ isLoading: false })
        if (err.response.status == 400) {
          MyToast.popToast(err.response.data.msg)
        } else if (err.response.status == 514) {
          this.props.expiredToken_logout()
          //console.log(err);
        }
      })
  }

  handle_amount = (val) => {
    this.setState({ amount: val })
  }

  onNextPressed = () => {
    if (this.state.amount != '') {
      Keyboard.dismiss()
      this.setState({ showPinModal: true })
    } else {
      MyToast.popToast(strings.t('invalid_data_input'))
    }
  }

  onBackPressed = () => {
    this.props.navigation.goBack()
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <EnterTransactionPinModal
          isShow={this.state.showPinModal}
          onPressForgotPin={() => this.onPress_forgot_pin()}
          onSuccEnterPin={() => this.onSuccEnterPin()}
          onBackModal={() => this.onBackModal()}
        />
        <MainFlowHeaderContainer
          title={strings.t('interest_withdraw')}
          onBackPress={() => this.onBackPressed()}
        >
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
            }}
          >
            <View
              style={{
                marginTop: HeightDimen(40),
              }}
            >
              <RecoverTextInput
                title={`${strings.t('withdraw_RM')} (${MyData.country_code})`}
                placeholder={'0.00'}
                top={true}
                bottom={true}
                isNumber={true}
                onChangeText={this.handle_amount}
              />
            </View>
            <View
              style={{
                width: '100%',
                alignItems: 'center',
                marginTop: HeightDimen(40),
              }}
            >
              <TextButton
                label={strings.t('next')}
                size={5}
                onPress={() => this.onNextPressed()}
                isLoading={this.state.isLoading}
              />
            </View>
          </View>
        </MainFlowHeaderContainer>
      </View>
    )
  }
}

export default PartialWithdrawScreen
