import React from 'react'
import {
  View,
  Image,
  TouchableOpacity,
  Platform,
  ImageBackground,
} from 'react-native'
import Text from '../Text/MyText'
import { MainColor, FontThirdColor, SecondaryColor } from '../../UI/Color'
import { WidthDimen, HeightDimen, fontDimen } from '../../UI/Dimensions'

const GameDetailsHeaderContainer = (props) => {
  const {
    onBackPress,
    title,
    toShow,
    toShowImage,
    toShowText,
    toShowPress,
    toShowImageUrl,
    children,
    landscape,
    hideBackButton,
  } = props

  return (
    <View
      style={{
        width: '100%',
        flex: 1,
        backgroundColor: SecondaryColor,
      }}
    >
      <View
        style={{
          height: landscape
            ? Platform.OS === 'ios'
              ? HeightDimen(15)
              : HeightDimen(15)
            : Platform.OS === 'ios'
            ? HeightDimen(35)
            : HeightDimen(25),
        }}
      ></View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            width: '100%',
            position: 'absolute',
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              fontSize: fontDimen(16),
              color: MainColor,
            }}
          >
            {title}
          </Text>
        </View>
        <View
          style={{
            height: HeightDimen(55),
            justifyContent: 'center',
            paddingLeft: HeightDimen(10),
          }}
        >
          {!hideBackButton && (
            <TouchableOpacity
              style={{
                width: HeightDimen(50),
                height: '100%',
                justifyContent: 'center',
              }}
              onPress={onBackPress}
            >
              <Image
                source={require('../../Resources/Images/icon_back.png')}
                style={{
                  width: 'auto',
                  height: HeightDimen(15),
                  aspectRatio: 19 / 13,
                  resizeMode: 'contain',
                  tintColor: MainColor,
                }}
              />
            </TouchableOpacity>
          )}
        </View>
        {toShow ? (
          <View
            style={{
              flex: 1,
              alignItems: 'flex-end',
            }}
          >
            {!hideBackButton && (
              <TouchableOpacity onPress={toShowPress}>
                <Text
                  style={{
                    fontSize: fontDimen(11),
                    color: MainColor,
                    marginRight: WidthDimen(20),
                  }}
                >
                  {toShowText}
                </Text>
              </TouchableOpacity>
            )}
          </View>
        ) : toShowImage ? (
          <View
            style={{
              flex: 1,
              alignItems: 'flex-end',
            }}
          >
            <TouchableOpacity
              onPress={toShowPress}
              style={{
                borderWidth: HeightDimen(1),
                borderColor: 'white',
                borderRadius: 9999,
                padding: HeightDimen(5),
                marginRight: landscape ? HeightDimen(50) : HeightDimen(20),
              }}
            >
              <Image
                source={toShowImageUrl}
                style={{
                  width: 'auto',
                  height: HeightDimen(18),
                  aspectRatio: 1 / 1,
                  resizeMode: 'contain',
                  tintColor: MainColor,
                }}
              />
            </TouchableOpacity>
          </View>
        ) : (
          <></>
        )}
      </View>

      {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}> */}
      <View
        style={{
          width: '100%',

          backgroundColor: 'white',
          // borderTopLeftRadius: HeightDimen(20),
          // borderTopRightRadius: HeightDimen(20),
          flex: 1,
        }}
      >
        <ImageBackground
          source={require('../../Resources/Images/bg.png')}
          style={styles.backgroundImage}
        >
          {children}
        </ImageBackground>
      </View>

      {/* </TouchableWithoutFeedback> */}
    </View>
  )
}

const styles = {
  backgroundImage: {
    width: '100%',
    flex: 1,
  },
}
export { GameDetailsHeaderContainer }
