import React, { Component } from 'react'
import { View } from 'react-native'
import Text from '../../Component/Text/MyText'
import { FontMainColor } from '../../UI/Color'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'
import { MainFlowHeaderContainer } from '../../Component/Header/MainFlowHeader'
import { strings } from '../../Language/Language'

class AboutScreen extends Component {
  constructor(props) {
    super(props)
  }

  state = {
    versionNumber: '4.1.0',
  }

  async componentDidMount() {
    //  let version = (await getDeviceInfo()).version;
    // this.setState({ versionNumber: version });
  }
  onBackPressed = () => {
    this.props.navigation.goBack()
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <MainFlowHeaderContainer
          title={strings.t('about')}
          onBackPress={() => this.onBackPressed()}
        >
          <View
            style={{
              flex: 1,
              paddingLeft: HeightDimen(30),
              paddingRight: HeightDimen(30),
              paddingTop: HeightDimen(30),
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                fontSize: fontDimen(15),
                color: FontMainColor,
                fontWeight: 'bold',
              }}
            >
              {strings.t('version')}
            </Text>
            <Text
              style={{
                fontSize: fontDimen(15),
                color: FontMainColor,
              }}
            >
              v {this.state.versionNumber}
            </Text>
          </View>
        </MainFlowHeaderContainer>
      </View>
    )
  }
}

export default AboutScreen
