import React, { Component } from 'react'
import { View, Image, TouchableOpacity, TextInput } from 'react-native'
import Text from '../Text/MyText'
import { strings } from '../../Language/Language'

import { MainColor, SecondaryColor } from '../../UI/Color'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'
import MyData from '../../Data/MyData'
import storageData from '../../Data/StorageData'

class MainFooter extends Component {
  state = {
    image_size: 20,
    selected: 1,
  }

  onSelect = (selection) => {
    if (selection === 3) {
      this.props.onUpdateSelection(selection)
    } else {
      if (this.state.selected !== selection) {
        this.props.onUpdateSelection(selection)
        this.setState({ selected: selection })
      }
    }
  }

  render() {
    const {} = this.props

    return (
      <View
        style={{
          width: '100%',
          flexDirection: 'row',

          backgroundColor: 'white',
          shadowColor: SecondaryColor,
          shadowOffset: {
            width: 1,
            height: 5,
          },
          shadowOpacity: 0.25,
          shadowRadius: 3.84,

          elevation: 5,
        }}
      >
        <TouchableOpacity
          onPress={() => this.onSelect(1)}
          style={{
            flex: 1,
            alignItems: 'center',
            paddingTop: HeightDimen(20),
            paddingBottom: HeightDimen(10),
          }}
        >
          <Image
            source={require('../../Resources/Images/Footer/icon_1.png')}
            style={{
              width: 'auto',
              height: HeightDimen(this.state.image_size),
              aspectRatio: 1 / 1,
              resizeMode: 'contain',
              tintColor: this.state.selected === 1 ? SecondaryColor : '#C7CBD8',
            }}
          />
          <Text
            style={{
              fontSize: fontDimen(11),
              color: this.state.selected === 1 ? SecondaryColor : '#C7CBD8',
            }}
          >
            {strings.t('footer_home')}
          </Text>
        </TouchableOpacity>

        {(MyData.directLine == '0' || storageData.saved_authSession == '') && (
          <TouchableOpacity
            onPress={() => this.onSelect(2)}
            style={{
              flex: 1,
              alignItems: 'center',
              paddingTop: HeightDimen(20),
              paddingBottom: HeightDimen(10),
            }}
          >
            <Image
              source={require('../../Resources/Images/Footer/icon_3.png')}
              style={{
                width: 'auto',
                height: HeightDimen(this.state.image_size),
                aspectRatio: 1 / 1,
                resizeMode: 'contain',
                tintColor:
                  this.state.selected === 2 ? SecondaryColor : '#C7CBD8',
              }}
            />
            <Text
              style={{
                fontSize: fontDimen(11),
                color: this.state.selected === 2 ? SecondaryColor : '#C7CBD8',
              }}
            >
              {strings.t('footer_contact')}
            </Text>
          </TouchableOpacity>
        )}
        {(MyData.directLine == '0' || storageData.saved_authSession == '') && (
          <View
            style={{
              flex: 1,
              alignItems: 'center',
            }}
          >
            <TouchableOpacity
              onPress={() => this.onSelect(3)}
              style={{
                width: 'auto',
                height: HeightDimen(80),
                aspectRatio: 1 / 1,
                overflow: 'hidden',
                borderRadius: HeightDimen(10),
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                bottom: HeightDimen(10),
              }}
            >
              <Image
                source={require('../../Resources/Images/icon_latest.png')}
                style={{
                  width: 'auto',
                  height: HeightDimen(80),
                  aspectRatio: 1 / 1,
                  resizeMode: 'contain',
                }}
              />
            </TouchableOpacity>
          </View>
        )}
        <TouchableOpacity
          onPress={() => this.onSelect(4)}
          style={{
            flex: 1,
            alignItems: 'center',
            paddingTop: HeightDimen(20),
            paddingBottom: HeightDimen(10),
          }}
        >
          <Image
            source={require('../../Resources/Images/Footer/icon_2.png')}
            style={{
              width: 'auto',
              height: HeightDimen(this.state.image_size),
              aspectRatio: 1 / 1,
              resizeMode: 'contain',
              tintColor: this.state.selected === 4 ? SecondaryColor : '#C7CBD8',
            }}
          />
          <Text
            style={{
              fontSize: fontDimen(11),
              color: this.state.selected === 4 ? SecondaryColor : '#C7CBD8',
            }}
          >
            {strings.t('footer_transaction')}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => this.onSelect(5)}
          style={{
            flex: 1,
            alignItems: 'center',
            paddingTop: HeightDimen(20),
            paddingBottom: HeightDimen(10),
          }}
        >
          <Image
            source={require('../../Resources/Images/Footer/icon_4.png')}
            style={{
              width: 'auto',
              height: HeightDimen(this.state.image_size),
              aspectRatio: 1 / 1,
              resizeMode: 'contain',
              tintColor: this.state.selected === 5 ? SecondaryColor : '#C7CBD8',
            }}
          />
          <Text
            style={{
              fontSize: fontDimen(11),
              color: this.state.selected === 5 ? SecondaryColor : '#C7CBD8',
            }}
          >
            {strings.t('footer_me')}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }
}

export default MainFooter
