import React, { Component } from 'react'
import {
  View,
  TouchableOpacity,
  Modal,
  Image,
  ScrollView,
  ImageBackground,
} from 'react-native'
import Text from '../Text/MyText'
import { SecondaryColor, FontSecondaryColor } from '../../UI/Color'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'
import { strings } from '../../Language/Language'
import MyData from '../../Data/MyData'

import { CameraRoll } from '@react-native-camera-roll/camera-roll'
import ViewShot from 'react-native-view-shot'
import MyToast from '../../Component/Toast/MyToast'
import { onShare } from '../../Utils/Common'
import Clipboard from '@react-native-clipboard/clipboard'
import { IconTextCard } from '../Card/NewDesign/IconTextCard'
import ImagePicker from 'expo-image-picker'
import * as MediaLibrary from 'expo-media-library'
import FileSaver from 'file-saver'
class QRModal extends Component {
  componentDidMount() {
    console.log(MyData.qrCode)
  }
  // hasAndroidPermission = async () => {
  //   const permission = PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE

  //   const hasPermission = await PermissionsAndroid.check(permission)
  //   if (hasPermission) {
  //     return true
  //   }

  //   const status = await PermissionsAndroid.request(permission)
  //   return status === 'granted'
  // }

  savePicture = async () => {
    await FileSaver.saveAs(MyData.qrCode)
  }

  shareQRCode = async () => {
    // if (Platform.OS === "android" && !(await this.hasAndroidPermission())) {
    //   return;
    // }

    // this.refs.viewShot.capture().then((uri) => {
    //   const options = {
    //     message: decodeURIComponent(MyData.refLink),
    //     url: uri,
    //     type: 'image/png',
    //   }
    //   onShare(MyData.qrCode)
    // })
    await onShare(MyData.qrCode)
  }
  onPressCopy = () => {
    Clipboard.setString(decodeURIComponent(MyData.refLink))
    MyToast.popToast(strings.t('copied'))
  }

  render() {
    const { isShow, onBackPress, username, onTransfer } = this.props

    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={isShow}
        statusBarTranslucent
      >
        <ScrollView
          style={{
            backgroundColor: 'white',
          }}
          contentContainerStyle={{
            flex: 1,
          }}
        >
          <View style={styles.backgroundImage}>
            <Image
              source={require('../../Resources/Images/splash-bg.png')}
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
              }}
            />
            <TouchableOpacity
              onPress={onBackPress}
              style={styles.closeButtonContainer}
            >
              <Image
                source={require('../../Resources/Images/close-cross.png')}
                style={styles.closeIcon}
              />
            </TouchableOpacity>
            <View style={styles.innerContainer}>
              <Image source={{ uri: MyData.avatar }} style={styles.avatar} />
              <Text style={styles.label}>
                {MyData.temp_json_object.response.user.member_username}
              </Text>
              <ViewShot ref="viewShot" style={styles.qrContainer}>
                <Image style={styles.qrImage} source={{ uri: MyData.qrCode }} />
              </ViewShot>
              <Text
                style={{
                  fontSize: fontDimen(12),
                  fontWeight: 'bold',
                  marginTop: HeightDimen(10),
                  // textAlign: "center",
                  width: '80%',
                  color: 'white',
                }}
              >
                {MyData.country_code == 'THB'
                  ? strings.t('qr_description_thb')
                  : strings.t('qr_description_myr')}
              </Text>
              <View style={styles.button}>
                <IconTextCard
                  src={require('../../Resources/Images/download.png')}
                  name={strings.t('qr_code_saved')}
                  hideShowNext
                  onPress={() => this.savePicture()}
                  marginRight={HeightDimen(5)}
                />
                <IconTextCard
                  src={require('../../Resources/Images/Footer/icon_qr.png')}
                  name={strings.t('qr_transfer')}
                  hideShowNext
                  onPress={onTransfer}
                  marginRight={HeightDimen(5)}
                />
                <IconTextCard
                  src={require('../../Resources/Images/share.png')}
                  name={strings.t('qr_qr_share')}
                  hideShowNext
                  onPress={() => this.shareQRCode()}
                  marginRight={HeightDimen(5)}
                />
                <IconTextCard
                  src={require('../../Resources/Images/icon_copy.png')}
                  name={strings.t('qr_copy_link')}
                  hideShowNext
                  onPress={() => this.onPressCopy()}
                />
              </View>
            </View>
          </View>
        </ScrollView>
      </Modal>
    )
  }
}
const styles = {
  backgroundImage: {
    flex: 1,
  },
  button: {
    width: '60%',
    flexDirection: 'row',
    marginTop: HeightDimen(25),
    // paddingHorizontal: HeightDimen(35),
    alignItems: 'center',
  },
  qrContainer: {
    marginTop: HeightDimen(20),
    borderRadius: HeightDimen(20),
    overflow: 'hidden',
    borderWidth: HeightDimen(5),
    borderColor: SecondaryColor,
  },
  qrImage: {
    width: 'auto',
    height: HeightDimen(230),
    aspectRatio: 1 / 1,
    resizeMode: 'contain',
  },
  closeButtonContainer: {
    marginTop: HeightDimen(40),
    backgroundColor: 'gray',
    width: HeightDimen(35),

    height: HeightDimen(35),
    marginLeft: HeightDimen(20),
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: HeightDimen(22),
  },
  closeIcon: {
    width: 'auto',
    height: HeightDimen(15),
    aspectRatio: 1 / 1,
    resizeMode: 'contain',
    tintColor: 'white',
  },
  label: {
    color: FontSecondaryColor,
    fontSize: fontDimen(18),
    fontWeight: 'bold',
    marginTop: HeightDimen(15),
  },
  avatar: {
    width: 'auto',
    height: HeightDimen(100),
    aspectRatio: 1 / 1,
    borderRadius: HeightDimen(10),
  },
  innerContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
}
export { QRModal }
