import React, { Component } from 'react'
import {
  View,
  Modal,
  FlatList,
  Text,
  TouchableOpacity,
  ScrollView,
} from 'react-native'
import { fontDimen, HeightDimen } from '../../UI/Dimensions'
import { strings } from '../../Language/Language'
import MyData from '../../Data/MyData'
import walletAPICaller from '../../API/WalletAPICaller'
import MyToast from '../../Component/Toast/MyToast'
import { TextButton } from '../../Component/Button/TextButton'
import { FontMainColor, MainColor } from '../../UI/Color'

import { LoginFlowHeaderContainer, NormalTextInput } from '../../Component'
import Clipboard from '@react-native-clipboard/clipboard'

class WithdrawUSDTScreen extends Component {
  state = {
    amount: '',
    isLoading: false,
    platformList: [],
    isModalShow: false,
    address: '',
    selectedPlatform: strings.t('select_platrform'),
    availableBalance: '',
    conversionRate: '',
  }

  async componentDidMount() {
    await walletAPICaller
      .onGetAvailableBalance(MyData.authToken)
      .then((res) => {
        this.setState({
          platformList: res.data?.response?.withdraw_networks,
          availableBalance: res.data?.response?.usdt_wallet_balance,
          conversionRate: res.data?.response?.usdt_conversion_rate,
        })
      })
      .catch((err) => {
        if (err.response.status == 514) {
          this.props.expiredToken_logout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
  }
  onWithdrawUSDTPreview = async () => {
    if (this.state.amount && this.state.address) {
      this.setState({ isLoading: true })
      const platform =
        this.state.selectedPlatform == strings.t('select_platrform')
          ? ''
          : this.state.selectedPlatform
      await walletAPICaller
        .onPreviewWithdrawUSDT(
          MyData.authToken,
          platform,
          this.state.address,
          this.state.amount
        )
        .then((res) => {
          this.props.navigation.navigate('WithdrawUsdtReceipt', {
            data: res.data.response,
            onGoBack: (data) => {
              this.onBackPressed(data)
            },
          })
        })
        .catch((err) => {
          if (err.response.status == 514) {
            this.props.expiredToken_logout()
          } else {
            MyToast.popToast(err.response.data.msg)
          }
        })
        .finally(() => {
          this.setState({
            isLoading: false,
          })
        })
    } else {
      MyToast.popToast(strings.t('please_enter_amount'))
    }
  }

  onBackPressed = () => {
    if (this.props.route.params?.backToRedeem) {
      this.props.navigation.pop(2)
      return
    }
    this.props.route.params?.onGoBack()
    this.props.navigation?.goBack()
  }
  handle_amount = (val) => {
    this.setState({ amount: val })
  }
  handle_address = (val) => {
    this.setState({ address: val })
  }
  handle_promo = (val) => {
    this.setState({ promo_code: val })
  }
  renderCurrencyPlatforModal = () => {
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={this.state.isModalShow}
        statusBarTranslucent
      >
        <View
          style={{
            flex: 1,
            backgroundColor: 'rgba(0,0,0,0.5)',
          }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: 'flex-end',
            }}
          >
            <View
              style={{
                backgroundColor: 'white',
                padding: HeightDimen(25),
              }}
            >
              <Text
                style={{
                  fontSize: fontDimen(15),
                  color: MainColor,
                  marginBottom: HeightDimen(15),
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                {strings.t('platform')}
              </Text>

              <FlatList
                numColumns={1}
                data={this.state.platformList}
                renderItem={({ item, index }) => (
                  <View>
                    <TouchableOpacity
                      onPress={() => {
                        this.setState({
                          selectedPlatform: item,
                          isModalShow: false,
                        })
                      }}
                      style={{
                        paddingTop: HeightDimen(15),
                        paddingBottom: HeightDimen(15),
                      }}
                    >
                      <Text
                        style={{
                          fontSize: fontDimen(15),
                          color: FontMainColor,
                          textAlign: 'center',
                        }}
                      >
                        {item}
                      </Text>
                    </TouchableOpacity>
                    <View
                      style={{
                        width: '95%',
                        height: HeightDimen(1),
                        backgroundColor: '#EFF2F4',
                      }}
                    ></View>
                  </View>
                )}
                keyExtractor={(item, index) => String(index)}
              />
            </View>
          </View>
        </View>
      </Modal>
    )
  }
  render() {
    return (
      <ScrollView
        style={{
          flex: 1,
        }}
      >
        <View
          style={{
            flex: 1,
            paddingVertical: HeightDimen(15),
            paddingHorizontal: HeightDimen(15),
          }}
        >
          <TouchableOpacity
            onPress={() => this.setState({ isModalShow: true })}
            style={{
              width: '100%',
              height: HeightDimen(50),
              backgroundColor: '#EFF2F4',
              flexDirection: 'row',
              alignItems: 'center',
              padding: HeightDimen(15),
              marginVertical: HeightDimen(5),
              borderRadius: HeightDimen(10),
            }}
          >
            <Text
              style={{
                flex: 1,
                fontSize: fontDimen(14),
                color: MainColor,
              }}
            >
              {this.state.selectedPlatform}
            </Text>
          </TouchableOpacity>

          <NormalTextInput
            placeholder={strings.t('address')}
            marginBottom={HeightDimen(5)}
            hideImage={true}
            onChangeText={this.handle_address}
            value={this.state.address}
          />
          <NormalTextInput
            placeholder={strings.t('amount_RM')}
            marginBottom={HeightDimen(20)}
            hideImage={true}
            isNumber={true}
            onChangeText={this.handle_amount}
            value={this.state.amount}
          />
          <Text
            style={{
              fontSize: fontDimen(13),
              color: MainColor,
              fontWeight: 'bold',
            }}
          >
            {strings.t('conversion_rate')}: {this.state.conversionRate}
          </Text>
          <Text
            style={{
              fontSize: fontDimen(13),
              color: MainColor,
              fontWeight: 'bold',
            }}
          >
            {strings.t('available_usdt')}: {this.state.availableBalance} USDT
          </Text>
          <Text
            style={{
              fontSize: fontDimen(13),
              color: MainColor,
              fontWeight: 'bold',
              marginTop: HeightDimen(10),
            }}
          >
            {strings.t('withdraw_usdt_intro')}
          </Text>
          <View
            style={{
              marginTop: HeightDimen(30),
            }}
          >
            <TextButton
              label={strings.t('next')}
              size={5}
              onPress={() => this.onWithdrawUSDTPreview()}
              isLoading={this.state.isLoading}
            />
          </View>
        </View>
        {this.renderCurrencyPlatforModal()}
      </ScrollView>
    )
  }
}

export default WithdrawUSDTScreen
