import React, { Component } from 'react'
import { View, TouchableOpacity, Modal, Image } from 'react-native'
import Text from '../Text/MyText'

import {
  MainColor,
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
  FontThirdColor,
} from '../../UI/Color'
import {
  WidthDimen,
  HeightDimen,
  fontDimen,
  GetWidth,
  GetHeight,
} from '../../UI/Dimensions'

import { ImageButton } from '../../Component/Button/ImageButton'
import { TextButton } from '../../Component/Button/TextButton'
import { strings } from '../../Language/Language'
import { useState } from 'react'

const ConfirmCancelModal = (props) => {
  const [cancelLabel, setCancelLabel] = useState(
    props?.cancelLabel ? props?.cancelLabel : strings.t('cancel')
  )
  const [confirmLabel, setConfirmLabel] = useState(
    props?.confirmLabel ? props?.confirmLabel : strings.t('confirm')
  )
  const [title, setTitle] = useState(
    props?.title ? props?.title : strings.t('cancellation')
  )
  const { isShow, onConfirmPress, onBackPress, textResource, isLoading } = props

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isShow}
      statusBarTranslucent
    >
      <View
        style={{
          flex: 1,
          backgroundColor: 'rgba(0,0,0,0.5)',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            backgroundColor: 'white',
            padding: HeightDimen(20),
            marginHorizontal: HeightDimen(20),
            alignItems: 'center',
            borderRadius: HeightDimen(10),
          }}
        >
          <Text
            style={{
              fontSize: fontDimen(20),
              color: 'red',
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            {title}
          </Text>
          <Text
            style={{
              fontSize: fontDimen(17),
              color: FontMainColor,
              textAlign: 'center',
              marginTop: 10,
            }}
          >
            {textResource}
          </Text>

          <View
            style={{
              marginTop: HeightDimen(40),
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            <TextButton onPress={onBackPress} label={cancelLabel} size={2.5} />
            <View
              style={{
                marginLeft: WidthDimen(10),
              }}
            >
              <TextButton
                onPress={onConfirmPress}
                label={confirmLabel}
                blackBtn={true}
                size={2.5}
                isLoading={isLoading}
              />
            </View>
          </View>
        </View>
      </View>
    </Modal>
  )
}

export { ConfirmCancelModal }
