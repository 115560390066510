import React, { Component } from 'react'
import { View } from 'react-native'
import { HeightDimen } from '../../UI/Dimensions'
import { MainFlowHeaderContainer } from '../../Component/Header/MainFlowHeader'
import { TextSelection } from '../../Component/Selection/TextSelection'
import storageData from '../../Data/StorageData'
import { strings } from '../../Language/Language'
import aPICaller from '../../API/APICaller'
import MyData from '../../Data/MyData'

import MyToast from '../../Component/Toast/MyToast'

class LanguageScreen extends Component {
  constructor(props) {
    super(props)
  }

  state = {
    selection: 1,
  }

  componentDidMount() {
    if (storageData.selected_language == 'en') {
      this.setState({ selection: 1 })
    } else if (storageData.selected_language == 'ch') {
      this.setState({ selection: 2 })
    } else if (storageData.selected_language == 'bm') {
      this.setState({ selection: 3 })
    } else if (storageData.selected_language == 'th') {
      this.setState({ selection: 4 })
    } else if (storageData.selected_language == 'vt') {
      this.setState({ selection: 5 })
    } else if (storageData.selected_language == 'id') {
      this.setState({ selection: 6 })
    } else if (storageData.selected_language == 'my') {
      this.setState({ selection: 7 })
    } else if (storageData.selected_language == 'kh') {
      this.setState({ selection: 8 })
    }
  }
  onLanguageChange = async (lang) => {
    await aPICaller
      .changeLanguage(MyData.authToken, lang)
      .then(async (res) => {
        console.log('@LANG RES', res.data)
        MyToast.popToast('Status: ' + res?.data?.msg)
      })
      .catch((err) => {
        console.log('LANG ERROR', err)
        MyToast.popToast(err.response.data.msg)
      })
  }
  onBackPressed = () => {
    this.props.navigation.goBack()
  }

  change_selection = async (id) => {
    if (this.state.selection != id) {
      this.setState({ selection: id })
      if (id == 1) {
        storageData.set_language('en')

        await this.onLanguageChange('en')
        // MyToast.popToast('Please restart the app to change the language.');
      } else if (id == 2) {
        storageData.set_language('ch')

        await this.onLanguageChange('ch')
        // MyToast.popToast('请重新启动应用程序以更改语言。')
      } else if (id == 3) {
        storageData.set_language('bm')

        await this.onLanguageChange('bm')
        //MyToast.popToast('Sila mulakan semula aplikasi untuk menukar bahasa.');
      } else if (id == 4) {
        storageData.set_language('th')

        await this.onLanguageChange('th')
        //MyToast.popToast('Sila mulakan semula aplikasi untuk menukar bahasa.');
      } else if (id == 5) {
        storageData.set_language('vt')

        await this.onLanguageChange('vt')
        //MyToast.popToast('Sila mulakan semula aplikasi untuk menukar bahasa.');
      } else if (id == 6) {
        storageData.set_language('id')

        await this.onLanguageChange('id')
        //MyToast.popToast('Sila mulakan semula aplikasi untuk menukar bahasa.');
      } else if (id == 7) {
        storageData.set_language('my')

        await this.onLanguageChange('my')
        //MyToast.popToast('Sila mulakan semula aplikasi untuk menukar bahasa.');
      } else if (id == 8) {
        storageData.set_language('kh')

        await this.onLanguageChange('kh')
        //MyToast.popToast('Sila mulakan semula aplikasi untuk menukar bahasa.');
      }
      this.setState({})
    }
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
        }}
      >
        <MainFlowHeaderContainer
          title={strings.t('language')}
          onBackPress={() => this.onBackPressed()}
        >
          <View
            style={{
              flex: 1,
              paddingHorizontal: HeightDimen(30),
              paddingTop: HeightDimen(20),
            }}
          >
            <TextSelection
              label={strings.t('eng_text')}
              id={1}
              current_selection={this.state.selection}
              onPress={() => this.change_selection(1)}
            />
            <View style={{ height: HeightDimen(3) }}></View>
            <TextSelection
              label={strings.t('ch_text')}
              id={2}
              current_selection={this.state.selection}
              onPress={() => this.change_selection(2)}
            />
            <View style={{ height: HeightDimen(3) }}></View>
            <TextSelection
              label={strings.t('bahasa_melayu_text')}
              id={3}
              current_selection={this.state.selection}
              onPress={() => this.change_selection(3)}
            />
            <View style={{ height: HeightDimen(3) }}></View>
            <TextSelection
              label={strings.t('thai_text')}
              id={4}
              current_selection={this.state.selection}
              onPress={() => this.change_selection(4)}
            />
            <View style={{ height: HeightDimen(3) }}></View>
            <TextSelection
              label={strings.t('vt_text')}
              id={5}
              current_selection={this.state.selection}
              onPress={() => this.change_selection(5)}
            />
            <View style={{ height: HeightDimen(3) }}></View>
            <TextSelection
              label={strings.t('indonsia_text')}
              id={6}
              current_selection={this.state.selection}
              onPress={() => this.change_selection(6)}
            />
            <View style={{ height: HeightDimen(3) }}></View>
            <TextSelection
              label={strings.t('myanmar_text')}
              id={7}
              current_selection={this.state.selection}
              onPress={() => this.change_selection(7)}
            />
            <View style={{ height: HeightDimen(3) }}></View>
            <TextSelection
              label={strings.t('cambodia_text')}
              id={8}
              current_selection={this.state.selection}
              onPress={() => this.change_selection(8)}
            />
          </View>
        </MainFlowHeaderContainer>
      </View>
    )
  }
}

export default LanguageScreen
