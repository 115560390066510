import React, { Component } from 'react'
import {
  View,
  Image,
  TouchableOpacity,
  ScrollView,
  ImageBackground,
  ActivityIndicator,
  RefreshControl,
} from 'react-native'
import Text from '../../Component/Text/MyText'
import {
  SecondaryColor,
  FontMainColor,
  FontSecondaryColor,
  MainColor,
} from '../../UI/Color'
import { HeightDimen, fontDimen } from '../../UI/Dimensions'
import MyData from '../../Data/MyData'
import { strings } from '../../Language/Language'
import { AvatarModal } from '../../Component/Modal/AvatarModal'
import MyToast from '../../Component/Toast/MyToast'
import { CoinCard } from '../../Component/Card/CoinCard'
import { IconTextCard } from '../../Component/Card/NewDesign/IconTextCard'
import walletAPICaller from '../../API/WalletAPICaller'
import { TopUpModal } from '../../Component/Modal/NewDesign/TopUpModal'
import { QRModal } from '../../Component/Modal/QRModal'
import { check_camera_permission } from '../../Permission/Permission'
import { Line } from '../../Component/Line/Line'
import { CountryCodeModal } from '../../Component/Modal/CountryCodeModal'
import avatarCaller from '../../API/AvatarCaller'
import { AllWalletHistoryCard } from '../../Component/Card/History/NewDesign/AllWalletHistoryCard'
import { SuccessModal } from '../../Component/Modal/SuccessModal'
import { TransferBalanceModal } from '../../Component/Modal/TransferBalanceModal'
import bankAPICaller from '../../API/BankAPICaller'
import { getVIPLevelLogo, numberWithCommas } from '../../Utils/Common'
import MIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import notificationCaller from '../../API/NotificationCaller'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Camera } from 'expo-camera'
class ProfileScreen extends Component {
  state = {
    name: '',
    uuid: '',
    refName: '',
    showAvatarModal: false,
    walletBalance: '0.00',
    coinBalance: '0.00',
    interesetBalance: '0.00',
    isLoading: false,
    show_top_up_modal: false,
    topupList: [],
    showQRCodeModal: false,
    isCountryModal: false,
    countryList: MyData.country_list,
    isCountryLoading: false,
    selectedCountry: 'Select country',
    selection: 1,
    gameTransactions: [],
    orderTransactions: [],
    walletTransactions: [],
    isTransferBalanceModalShow: false,
    showPinModal: false,
    successTransferModal: false,
    successTransferMsg: {},
    bankAccountList: [],

    isFetchingTopupDetails: false,
    isDisabledQRTopup: false,

    notificationCounter: MyData.notifCount,
  }
  constructor(props) {
    super(props)
    this.transferModalRef = React.createRef()
  }
  async componentDidMount() {
    //  logScreenViewAnalytics(screenNames.Profile);

    await this.set_info()
    await this.get_profile_data()
  }

  get_profile_data = async (wantLoading = true) => {
    wantLoading && this.setState({ isLoading: true })
    await walletAPICaller
      .onGetProfileData(MyData.authToken)
      .then((res) => {
        // logMessageAnalytics(AnalyticEvents.ProfileDataSuccess);
        const baseModel = res.data.response
        console.log('@PROFILE', baseModel)

        this.setState({
          walletBalance: baseModel.walletBalance,
          coinBalance: baseModel.coinBalance,
          interesetBalance: baseModel.interestBalance,
          gameTransactions: baseModel.gameTransactions,
          orderTransactions: baseModel.orderTransactions,
          walletTransactions: baseModel.walletTransactions,
          bankList: baseModel.topUpBankList,
          bankAccountList: baseModel.accounts,
          topupList: baseModel.topUpMethodList,
          countryList: baseModel.countryList,
          notificationCounter: baseModel?.totalUnreadNotifications,
        })
        MyData.setNotifCount(baseModel?.totalUnreadNotifications)
        MyData.temp_json_object.response.user.walletBalance =
          baseModel.walletBalance
        MyData.temp_json_object.response.user.coinBalance =
          baseModel.coinBalance
        MyData.temp_json_object.response.user.interestBalance =
          baseModel.interestBalance
        MyData.country_list = baseModel.countryList
        MyData.topupList = baseModel.topUpMethodList
      })
      .catch((err) => {
        console.log('@ERROR', err)
        // logMessageAnalytics(
        //   AnalyticEvents.ProfileDataFailure,
        //   err.response.data.msg
        // );
        if (err.response.status == '514') {
          this.onExpireTokenLogout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  switchCountry = async (code, countryName, countryUrl, countryId) => {
    this.setState({ isCountryLoading: true })

    await avatarCaller
      .switchCountry(MyData.authToken, code)
      .then(async (res) => {
        MyData.country_url = countryUrl
        MyData.country_name = countryName
        MyData.country_code = code
        MyData.country_id = countryId
        // MyData.setS3CountryURL();
        await AsyncStorage.setItem('countryCode', code)
        this.setState({ isCountryModal: false })
        this.refresh()
      })
      .catch((err) => {
        if (err.response.status == '514') {
          this.onExpireTokenLogout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isCountryLoading: false, isCountryModal: false })
      })
  }
  set_info = () => {
    this.setState({
      name: MyData.temp_json_object.response.user.member_username,
      uuid: MyData.temp_json_object.response.user.id,
      refName: MyData.temp_json_object.response.user.refMemberName,
      // walletBalance: MyData.temp_json_object.response.user.walletBalance,
      //coinBalance: MyData.temp_json_object.response.user.coinBalance,
      //interesetBalance: MyData.temp_json_object.response.user.interestBalance,
    })
    this.props.navigation.addListener('focus', () => {
      this.setState({})
    })
  }

  onPress_edit = () => {
    this.props.navigation.navigate('EditInfo')
  }

  onPress_wallet = () => {
    this.props.navigation.navigate('AllBalance')
  }

  onGetTopUpWithrawStatus = (item) => {
    const status = `${
      item.topup_status ? item.topup_status : item.withdraw_status
    }`

    if (status == 'rejected') return strings.t('rejected')
    if (status == 'hold') return strings.t('hold')
    if (status == 'pending') return strings.t('pending')
    if (status == 'approve') return strings.t('approved')
    if (status == 'process') return strings.t('processing')
    return status
  }

  onPress_bacnAcc = () => {
    // logMessageAnalytics(AnalyticEvents.ProfileBankAccountClick);
    this.props.navigation.navigate('BankAcc', {
      onGoBack: (data) => {
        this.refresh(data)
      },
    })
  }
  onPress_currencyExchange = () => {
    // logMessageAnalytics(AnalyticEvents.ProfileCurrencyExchangeClick);
    this.props.navigation.navigate('CurrencyExchange', {
      onGoBack: (data) => {
        this.refresh(data)
      },
    })
  }
  go_interest = () => {
    //logMessageAnalytics(AnalyticEvents.ProfileLucktCoinClick);
    this.props.navigation.navigate('Interest', {
      onGoBack: (data) => {
        this.refresh(data)
      },
    })
  }
  onPress_chageAvatar = (status) => {
    if (status) {
      this.setState({})
      MyToast.popToast(strings.t('avatar_changed'))
    } else {
      MyToast.popToast(strings.t('error_please_try_again'))
    }
    this.toggle_avatar()
  }

  toggle_avatar = () => {
    this.setState({
      showAvatarModal: !this.state.showAvatarModal,
    })
  }

  onPress_setting = () => {
    // logMessageAnalytics(AnalyticEvents.ProfileSettingClick);
    this.props.navigation.navigate('Setting', {
      onGoBack: (data) => {
        this.refresh(data)
      },
    })
  }

  refresh = async (data) => {
    await this.get_profile_data(true)
  }

  onPress_ChangeNickname = () => {
    this.props.navigation.navigate('ChangeNickName', {
      onGoBack: (data) => {
        this.refresh(data)
      },
    })
  }
  onPress_transaction = () => {
    this.props.navigation.navigate('Transaction', {
      onGoBack: (data) => {
        this.refresh(data)
      },
    })
  }

  onPress_customerService = () => {
    // logMessageAnalytics(AnalyticEvents.ProfileHelpDeskClick);
    this.props.navigation.navigate('CustomerService', {
      onGoBack: (data) => {
        this.refresh(data)
      },
    })
  }
  onPress_transfer = () => {
    //logMessageAnalytics(AnalyticEvents.ProfileTransferClick);
    // this.setState({ isTransferBalanceModalShow: true });
    if (MyData.temp_json_object?.response?.user?.wallet_pin_status == 'true') {
      this.props.navigation.navigate('TransferManual', {
        onGoBack: (data) => {
          this.refresh(data)
        },
      })
    } else {
      this.props.navigation.navigate('CreatePinVerification', {
        page: 'TransferManual',
        onGoBack: (data) => {
          this.refresh(data)
        },
      })
    }
  }
  onPressTransferClose = () => {
    this.setState({ isTransferBalanceModalShow: false })
  }

  onPress_redeem = () => {
    // logMessageAnalytics(AnalyticEvents.GameDetailRedeemClick);
    this.setState({
      show_top_up_modal: false,
    })
    this.props.navigation.navigate('Coupon', {
      onGoBack: (data) => {
        this.on_Redeem_refresh(data)
        this.refresh()
      },
      onBackOpenModal: () => {
        this.setState({
          show_top_up_modal: true,
        })
      },
    })
  }

  onPress_InstantTopUp = () => {
    // logMessageAnalytics(AnalyticEvents.ProfileInstantTopupClick);
    this.setState({
      show_top_up_modal: false,
    })
    this.props.navigation.navigate('InstantTopUp', {
      onGoBack: (data) => {
        this.on_TopUp_refresh(data)
      },
    })
  }
  onPress_UsdtTopUp = () => {
    //  logMessageAnalytics(AnalyticEvents.ProfileUsdtTopupClick);
    this.setState({
      show_top_up_modal: false,
    })
    this.props.navigation.navigate('UsdtTopUp', {
      onGoBack: (data) => {
        this.refresh()
      },
    })
  }
  onPress_WithdrawUsdt = () => {
    //logMessageAnalytics(AnalyticEvents.ProfileWithdrawUsdtClick);
    this.setState({
      show_top_up_modal: false,
    })
    this.props.navigation.navigate('WithdrawUsdt', {
      onGoBack: (data) => {
        this.refresh()
      },
    })
  }
  on_TopUp_refresh = (data) => {
    if (data) MyToast.popToast(strings.t('top_up_succ'))
  }

  on_Redeem_refresh = (data) => {
    if (data) MyToast.popToast(strings.t('code_redeem_success'))
  }

  onPress_BankTopUp = () => {
    //l/ogMessageAnalytics(AnalyticEvents.ProfileBankTopupClick);
    this.setState({
      show_top_up_modal: false,
    })
    this.props.navigation.navigate('TopUp', {
      onGoBack: (data) => {
        this.on_TopUp_refresh(data)
        this.refresh()
      },
    })
  }

  onCancel_modal = () => {
    //logMessageAnalytics(AnalyticEvents.ProfileTopupWithdrawModalCloseClick);
    this.setState({
      show_top_up_modal: false,
    })
  }

  getAvailableWithdraw = async () => {
    // if (this.state.bankAccountList.length > 0) {
    //   this.setState({ isLoading: true });
    //   this.setState({
    //     show_top_up_modal: false,
    //   });
    this.setState({ isLoading: true, show_top_up_modal: false })
    await walletAPICaller
      .getAvailableWithdraw(MyData.authToken)
      .then((res) => {
        this.checkWithdraw()
      })
      .catch((err) => {
        if (err.response.status == '514') {
          this.props.expiredToken_logout()
        } else if (err.response.status == '303') {
          this.onPress_bacnAcc()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
    //}
    // else {
    //   this.onCancel_modal();
    //   this.onPress_bacnAcc();
    // }
  }
  checkWithdraw = async () => {
    this.setState({ isLoading: true })
    await walletAPICaller
      .onCheckWithdraw(MyData.authToken)
      .then((res) => {
        if (res.status == 200) {
          this.onPress_withdraw()
        } else if (res.status == 201) {
          this.props.navigation.navigate('WithdrawHistoryDetailsScreen', {
            bankList: [],
            selectedTopUpDetails: res.data.response.info,
            onGoBack: (data) => {
              this.refresh(data)
            },
          })
        }
      })
      .catch((err) => {
        if (err.response.status == '514') {
          this.onExpireTokenLogout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }
  onPress_topUp = () => {
    this.setState({
      //   show_top_up_modal: true,
      isFetchingTopupDetails: false,
    })
    this.props.navigation.navigate('TopupWithdraw', {
      selectionType: 1,
      onGoBack: (data) => {
        this.refresh(data)
      },
    })
  }
  get_topup_QR_Pay = async () => {
    await bankAPICaller
      .getQRTopup(MyData.authToken)
      .then((res) => {
        this.setState({ isDisabledQRTopup: false })
      })
      .catch((err) => {
        if (err.response.status == 400) {
          this.setState({ isDisabledQRTopup: true })
        }
      })
  }
  // get_topup_QR_Pay = async () => {
  //   this.setState({ isLoading: true });
  //   await bankAPICaller
  //     .getQRTopup(MyData.authToken)
  //     .then((res) => {
  //       this.setState({ isDisabledQRTopup: false });
  //     })
  //     .catch((err) => {
  //       if (err.response.status == 400) {
  //         this.setState({ isDisabledQRTopup: true });
  //       }
  //     })
  //     .finally(() => {
  //       this.setState({ isLoading: false });
  //     });
  // };
  checkTopUp = async () => {
    // if (this.state.isLoading) {
    //   return MyToast.popToast()
    // }
    //logMessageAnalytics(AnalyticEvents.ProfileTopupWithdrawModalClick);
    this.setState({ isFetchingTopupDetails: true })
    await walletAPICaller
      .onCheckTopUp(MyData.authToken)
      .then(async (res) => {
        if (res.status == 200) {
          await this.get_topup_QR_Pay()
          this.onPress_topUp()
        } else if (res.status == 201) {
          const data = res?.data?.response?.info
          if (data?.wallet_country == '1') MyData.wallet_country = 'MYR'
          else if (data?.wallet_country == '2') MyData.wallet_country = 'THB'
          this.props.navigation.navigate('TopUpDetailsScreen', {
            bankList: [],
            selectedTopUpDetails: res.data.response.info,
            onGoBack: (data) => {
              this.refresh(data)
            },
          })
          this.setState({ isFetchingTopupDetails: false })
        }
      })
      .catch((err) => {
        this.setState({ isFetchingTopupDetails: false })
        if (err.response.status == '514') {
          this.onExpireTokenLogout()
        } else {
          MyToast.popToast(err.response.data.msg)
        }
      })
    // .finally(() => {
    //   this.setState({ isFetchingTopupDetails: false });
    // });
  }

  onPress_withdraw = () => {
    if (MyData.temp_json_object?.response?.user?.wallet_pin_status == 'true') {
      this.props.navigation.navigate('Withdraw', {
        onGoBack: (data) => {
          this.refresh(data)
        },
      })
    } else {
      this.props.navigation.navigate('CreatePinVerification', {
        page: 'Withdraw',
        onGoBack: (data) => {
          this.refresh(data)
        },
      })
    }
  }
  onTransfer = async () => {
    // logMessageAnalytics(AnalyticEvents.QRModalTransferClick);
    if (MyData.temp_json_object?.response?.user?.wallet_pin_status == 'true') {
      const status = await Camera.requestCameraPermissionsAsync()

      if (status.status === 'granted') {
        this.setState({ showQRCodeModal: false })

        this.props.navigation.navigate('TransferCamera', {
          onGoBack: (data) => {
            this.refresh(data)
          },
        })
      } else {
        MyToast.popToast(strings.t('please_allow_camera_permission'))
      }
    } else {
      this.setState({ showQRCodeModal: false })
      this.props.navigation.navigate('CreatePinVerification', {
        page: 'TransferCamera',
        onGoBack: (data) => {
          this.refresh(data)
        },
      })
    }
  }
  onPress_qr_Topup = () => {
    //logMessageAnalytics(AnalyticEvents.ProfileQRTopupClick);
    this.setState({
      show_top_up_modal: false,
    })
    this.props.navigation.navigate('QRTopup', {
      onGoBack: (data) => {
        this.refresh(data)
      },
    })
  }
  onPress_Promotion_Topup = () => {
    //  logMessageAnalytics(AnalyticEvents.ProfilePromotionTopupClick);
    this.setState({
      show_top_up_modal: false,
    })
    this.props.navigation.navigate('Coupon', {
      onGoBack: (data) => {
        this.refresh()
      },
    })
  }
  onSwitch = (val) => {
    if (this.state.selection != val) {
      this.setState({ selection: val })
    }
  }

  renderTransactionType = (item) => {
    return item.transaction_type === 'gameIn'
      ? strings.t('enter_game')
      : item.transaction_type === 'gameOut'
      ? strings.t('exit_game')
      : item.transaction_type.toLowerCase() === 'transfer' &&
        item.transaction_money_type.toLowerCase() === 'money'
      ? item.transfer_fromto_member_name
      : item.transaction_type.toLowerCase() === 'interest' &&
        (item.transaction_money_type.toLowerCase() === 'interest' ||
          item.transaction_money_type.toLowerCase() === 'money')
      ? strings.t('interest')
      : item.product_name
  }
  onPress_forgot_pin = () => {
    this.setState({ showPinModal: false, isTransferBalanceModalShow: false })
    this.props.navigation.navigate('RecoverPin')
  }

  onSuccEnterPin = () => {
    this.setState({ showPinModal: false })
    this.transferModalRef.current.on_transfer()
  }

  onBackModal = () => {
    this.setState({ showPinModal: false })
  }

  onTransferBalanceSuccess = (obj) => {
    this.onPressTransferClose()
    this.refresh()
    // this.setState({ successTransferModal: true, successTransferMsg: obj });
  }

  onExpireTokenLogout = () => {
    this.props.expiredToken_logout()
    this.onPressTransferClose()
    this.onBackModal()
    this.onCancel_modal()
  }

  onPressTransactionMore() {
    const { selection } = this.state
    if (selection == 1) {
      // logMessageAnalytics(AnalyticEvents.ProfileWalletTabClick);
      this.props.navigation.navigate('AllWalletTransaction')
    } else if (selection == 2) {
      //  logMessageAnalytics(AnalyticEvents.ProfileGameTabClick);
      this.props.navigation.navigate('AllGameTransaction')
    } else if (selection == 3) {
      //  logMessageAnalytics(AnalyticEvents.ProfileOrdeTabrClick);
      this.onPress_transaction()
    }
  }

  onTransferConfirmPress = () => {
    this.setState({ showPinModal: true })
  }

  onPress_Wallet_transaction = (index) => {
    //logMessageAnalytics(AnalyticEvents.ProfileWalletTransactionClick);
    this.props.navigation.navigate('AllWalletTransactionDetail', {
      data: this.state.walletTransactions[index],
    })
  }

  onPress_WithdrawHistoryDetails = (index) => {
    // logMessageAnalytics(AnalyticEvents.ProfileWithdrawHistoryTransactionClick);
    this.props.navigation.navigate('WithdrawHistoryDetailsScreen', {
      bankList: this.state.bankList,
      selectedTopUpDetails: this.state.orderTransactions[index],
      onGoBack: (data) => {
        this.refresh(data)
      },
    })
  }

  onPress_TopUpHistoryDetails = (index) => {
    //logMessageAnalytics(AnalyticEvents.ProfileTopupTransactionClick);
    this.props.navigation.navigate('TopUpDetailsScreen', {
      bankList: this.state.bankList,
      selectedTopUpDetails: this.state.orderTransactions[index],
      onGoBack: (data) => {
        this.refresh(data)
      },
    })
  }

  renderFastImage = () => {
    return <Image source={{ uri: MyData.avatar }} style={styles.avatarImage} />
  }

  getNickname = () => {
    if (!MyData.memner_nickname) return ''
    if (String(MyData.memner_nickname).trim().length == 0) return ''
    return String(MyData.memner_nickname).trim()
  }

  onGetTopUpWithrawStatus = (item) => {
    const status = `${
      item.topup_status ? item.topup_status : item.withdraw_status
    }`

    if (status == 'rejected') return strings.t('rejected')
    if (status == 'hold') return strings.t('hold')
    if (status == 'pending') return strings.t('pending')
    if (status == 'approve') return strings.t('approved')
    if (status == 'process') return strings.t('processing')
    return status
  }

  getFromCurrencyFlag = (from) => {
    const arrUrl = MyData.country_list.filter((i) => i.code == from)
    if (arrUrl?.length > 0) {
      return arrUrl[0].icon_url
    }
  }
  onCountNotifications = async () => {
    await notificationCaller
      .getNotifications(MyData.authToken)
      .then((res) => {
        this.setState({
          notificationCounter: res?.response?.totalUnreadNotifications,
        })
        MyData.setNotifCount(res.response?.totalUnreadNotifications)
      })
      .catch((err) => {
        // logMessageAnalytics(
        //   AnalyticEvents.CountNotificationFailure,
        //   err.response.data.msg
        // );
      })
  }
  render() {
    return (
      <View style={{ flex: 1 }}>
        <Image
          source={require('../../Resources/Images/bg.png')}
          style={styles.backgroundImage}
        />
        {this.state.isTransferBalanceModalShow && (
          <TransferBalanceModal
            isShow={this.state.isTransferBalanceModalShow}
            ref={this.transferModalRef}
            closeModalPress={this.onPressTransferClose}
            transferConfirmPress={this.onTransferConfirmPress}
            onSucessTransfer={(obj) => this.onTransferBalanceSuccess(obj)}
            expiredTokenLogout={this.onExpireTokenLogout}
            navigation={this.props.navigation}
          />
        )}
        {/* <EnterTransactionPinModal
          isShow={this.state.showPinModal}
          onPressForgotPin={() => this.onPress_forgot_pin()}
          onSuccEnterPin={() => this.onSuccEnterPin()}
          onBackModal={() => this.onBackModal()}
        /> */}
        {this.state.successTransferModal && (
          <SuccessModal
            isShow={this.state.successTransferModal}
            isSuccess={this.state.successTransferMsg.isSuccess}
            message={this.state.successTransferMsg.transferMessage}
            transferAmount={this.state.successTransferMsg.transferAmount}
            onConfirmPress={() => {
              this.setState({ successTransferModal: false })
            }}
          />
        )}

        <AvatarModal
          isShow={this.state.showAvatarModal}
          updateAvatar={this.onPress_chageAvatar}
          toggleAvatar={this.toggle_avatar}
        />
        <CountryCodeModal
          isShow={this.state.isCountryModal}
          list={this.state.countryList}
          onPress={(item) => {
            this.switchCountry(
              item.code,
              item.name,
              item.icon_url,
              item.country_id
            )
          }}
          onBackPress={() => {
            this.setState({ isCountryModal: false })
          }}
        />
        <QRModal
          isShow={this.state.showQRCodeModal}
          onBackPress={() => {
            this.setState({ showQRCodeModal: false })
          }}
          username={MyData.member_username}
          onTransfer={() => this.onTransfer()}
        />
        {this.state.topupList.length > 0 && (
          <TopUpModal
            isShow={this.state.show_top_up_modal}
            topupList={this.state.topupList}
            onPressRedeem={() => this.onPress_redeem()}
            onPressInstantTopUp={() => this.onPress_InstantTopUp()}
            onPressBankTopUp={() => this.onPress_BankTopUp()}
            onPressUsdtTopUp={() => this.onPress_UsdtTopUp()}
            onBackPress={() => this.onCancel_modal()}
            onPressWithdraw={() => this.getAvailableWithdraw()}
            onPressQRTopup={() => this.onPress_qr_Topup()}
            onPressPromotionTopUp={() => this.onPress_Promotion_Topup()}
            isDisabledQRTopup={this.state.isDisabledQRTopup}
            onBackOpenModal={() => this.setState({ show_top_up_modal: true })}
            onPressWithdrawUsdt={() => this.onPress_WithdrawUsdt()}
          />
        )}

        <View
          style={{
            ...styles.container,
            //   paddingTop: HeightDimen(30),
          }}
        >
          <View style={styles.avatarContainer}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: HeightDimen(10),
                alignItems: 'center',
              }}
            >
              <TouchableOpacity
                onPress={() =>
                  this.props.navigation.navigate('Notification', {
                    onGoBack: (data) => {
                      this.refresh()
                    },
                  })
                }
              >
                <MIcon name="bell" size={30} color={SecondaryColor} />
                <View style={styles.notificationContainer}>
                  <Text
                    style={{
                      color: 'white',
                      fontSize: fontDimen(10),
                      marginBottom: 1,
                    }}
                  >
                    {this.state.notificationCounter}
                  </Text>
                </View>
              </TouchableOpacity>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {!this.state.isCountryLoading ? (
                  <TouchableOpacity
                    onPress={() => {
                      if (this.state.countryList?.length == 0) return
                      this.setState({ isCountryModal: true })
                    }}
                  >
                    <Image
                      source={{ uri: MyData.country_url }}
                      style={styles.flag}
                    />
                  </TouchableOpacity>
                ) : (
                  <ActivityIndicator color={SecondaryColor} size={'small'} />
                )}
                <TouchableOpacity
                  style={{ marginLeft: HeightDimen(10) }}
                  onPress={() => {
                    //logMessageAnalytics(AnalyticEvents.ProfileQRClick);
                    this.setState({ showQRCodeModal: true })
                  }}
                >
                  <Image
                    source={require('../../Resources/Images/Footer/icon_qr.png')}
                    style={styles.qr}
                  />
                </TouchableOpacity>
              </View>
            </View>

            <View style={styles.avatarInnerContainer}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    //  logMessageAnalytics(AnalyticEvents.ProfileAvatarClick);
                    this.toggle_avatar()
                  }}
                >
                  {this.renderFastImage()}
                </TouchableOpacity>
                <View style={styles.userInfoContainer}>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Text style={styles.nameLabel}>{this.state.name}</Text>
                    <TouchableOpacity
                      onPress={() => this.props.navigation.navigate('vip')}
                    >
                      <Image
                        source={require('../../Resources/Images/Profile/icon_vip.png')}
                        style={styles.vipIcon}
                      />
                    </TouchableOpacity>
                  </View>
                  <Text
                    style={{
                      color: FontSecondaryColor,
                      fontSize: fontDimen(12),
                      marginTop: HeightDimen(3),
                    }}
                  >
                    {/* {strings.LID + ": " + this.state.uuid} */}

                    {'UTC/GMT +8:00 (Malaysia)'}
                  </Text>
                  <TouchableOpacity
                    style={{ flexDirection: 'row', alignItems: 'center' }}
                    onPress={() => this.onPress_ChangeNickname()}
                  >
                    {MyData.memner_nickname ? (
                      <Text
                        style={{
                          color: FontSecondaryColor,
                          fontSize: fontDimen(12),
                          marginTop: HeightDimen(3),
                        }}
                      >
                        {strings.t('nickname') +
                          ': ' +
                          String(MyData.memner_nickname).trim()}
                      </Text>
                    ) : (
                      <View
                        style={{
                          borderRadius: HeightDimen(10),
                          padding: HeightDimen(5),
                          marginTop: HeightDimen(3),
                          backgroundColor: SecondaryColor,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Text
                          style={{
                            fontSize: fontDimen(12),
                            color: 'white',
                          }}
                        >
                          {strings.t('edit_nickname')}
                        </Text>
                      </View>
                    )}
                  </TouchableOpacity>
                  <Text
                    style={{
                      color: FontSecondaryColor,
                      fontSize: fontDimen(12),
                      marginTop: HeightDimen(3),
                    }}
                  >
                    {strings.t('referral') + ': ' + this.state.refName}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <ScrollView
            style={{ paddingHorizontal: HeightDimen(10) }}
            refreshControl={
              <RefreshControl refreshing={false} onRefresh={this.refresh} />
            }
          >
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <CoinCard
                title={strings.t('profile_cash')}
                balance={`${MyData.country_code} ${numberWithCommas(
                  Number(this.state.walletBalance).toFixed(2)
                )}`}
                src={require('../../Resources/Images/Wallet/icon_with.png')}
                isDisable
              />
              <CoinCard
                title={strings.t('profile_chip')}
                balance={`${MyData.country_code} ${numberWithCommas(
                  Number(this.state.coinBalance).toFixed(2)
                )}`}
                src={require('../../Resources/Images/Profile/icon_chip.png')}
                marginLeft={HeightDimen(10)}
                isDisable
              />
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginTop: HeightDimen(7),
              }}
            >
              <CoinCard
                title={strings.t('lt_coin')}
                balance={`${MyData.country_code} ${numberWithCommas(
                  Number(this.state.interesetBalance).toFixed(2)
                )}`}
                src={require('../../Resources/Images/Profile/icon_coin.png')}
                onPress={() => this.go_interest()}
              />
              <CoinCard
                title={'VIP'}
                marginLeft={HeightDimen(10)}
                balance={MyData.member_vip_level}
                src={require('../../Resources/Images/Profile/icon_vip.png')}
                vipLogo={getVIPLevelLogo() && getVIPLevelLogo()}
                isDisable
              />
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: HeightDimen(10),
              }}
            >
              <IconTextCard
                src={require('../../Resources/Images/Profile/icon_contact.png')}
                name={strings.t('helpDesk')}
                onPress={() => this.onPress_customerService()}
              />
              <IconTextCard
                src={require('../../Resources/Images/Profile/icon_topup.png')}
                name={`${strings.t('topup_withdraw')}`}
                onPress={() => this.checkTopUp()}
              />
              <IconTextCard
                src={require('../../Resources/Images/Profile/icon_transfer.png')}
                name={strings.t('transfer')}
                onPress={() => {
                  this.onPress_transfer()
                }}
              />
              <IconTextCard
                src={require('../../Resources/Images/Profile/money-exchange.png')}
                name={strings.t('currency_exchange')}
                onPress={() => {
                  this.onPress_currencyExchange()
                }}
              />
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginTop: HeightDimen(10),
                justifyContent: 'space-between',
              }}
            >
              <IconTextCard
                src={require('../../Resources/Images/Wallet/icon_bank.png')}
                name={strings.t('bank_account')}
                onPress={() => this.onPress_bacnAcc()}
              />
              <IconTextCard
                src={require('../../Resources/Images/Profile/icon_vip.png')}
                name={strings.t('profile_vip')}
                //disabled={MyData.country_code == "MYR"}
                onPress={() => this.props.navigation.navigate('vip')}
              />
              <IconTextCard
                src={require('../../Resources/Images/Profile/icon_promotion.png')}
                name={strings.t('promotion')}
                onPress={() => this.onPress_redeem()}
                // disabled={MyData.country_code == "MYR"}
              />

              <IconTextCard
                src={require('../../Resources/Images/Profile/icon_setting.png')}
                name={strings.t('setting')}
                onPress={() => {
                  this.onPress_setting()
                }}
              />
            </View>
            <View style={styles.tabContainer}>
              <View style={styles.tabLeftContainer}>
                <TouchableOpacity
                  style={{
                    ...styles.tabLeftButton,
                    borderBottomColor:
                      this.state.selection == 1 ? SecondaryColor : 'white',
                  }}
                  onPress={() => this.onSwitch(1)}
                >
                  <Text
                    style={{
                      ...styles.tabButtonLabel,
                      color:
                        this.state.selection == 1
                          ? SecondaryColor
                          : FontSecondaryColor,
                    }}
                  >
                    {strings.t('wallet')}
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={{
                    ...styles.tabLeftButton,
                    borderBottomColor:
                      this.state.selection == 2 ? SecondaryColor : 'white',
                  }}
                  onPress={() => this.onSwitch(2)}
                >
                  <Text
                    style={{
                      ...styles.tabButtonLabel,
                      color:
                        this.state.selection == 2
                          ? SecondaryColor
                          : FontSecondaryColor,
                    }}
                  >
                    {strings.t('game')}
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={{
                    ...styles.tabLeftButton,
                    borderBottomColor:
                      this.state.selection == 3 ? SecondaryColor : 'white',
                  }}
                  onPress={() => this.onSwitch(3)}
                >
                  <Text
                    style={{
                      ...styles.tabButtonLabel,
                      color:
                        this.state.selection == 3
                          ? SecondaryColor
                          : FontSecondaryColor,
                    }}
                  >
                    {strings.t('order')}
                  </Text>
                </TouchableOpacity>
              </View>
              <TouchableOpacity
                style={styles.tabRightButton}
                onPress={() => this.onPressTransactionMore()}
              >
                <Text
                  style={{
                    ...styles.tabButtonLabel,
                    marginRight: HeightDimen(5),
                  }}
                >
                  {strings.t('more')}
                </Text>
                <Image
                  source={require('../../Resources/Images/icon_nextP.png')}
                  style={styles.moreButtonLogo}
                />
              </TouchableOpacity>
            </View>

            {!this.state.isLoading ? (
              <View style={styles.listContainer}>
                {this.state.selection == 1 &&
                  this.state.walletTransactions.length > 0 &&
                  this.state.walletTransactions.map((item, index) => {
                    if (item.transaction_type == 'conversion') {
                      return (
                        <View key={index}>
                          <AllWalletHistoryCard
                            onPress={() =>
                              this.onPress_Wallet_transaction(index)
                            }
                            url={this.getFromCurrencyFlag(
                              item?.transaction_exchange_from
                            )}
                            title={this.renderTransactionType(item)}
                            date={item.transaction_created_datetime}
                            bet={item.product_name}
                            win={` ${
                              item.transaction_money_type == 'money'
                                ? MyData.country_code
                                : item.transaction_money_type == 'coin'
                                ? strings.t('coin')
                                : ''
                            } ${numberWithCommas(
                              Number(item.transaction_amount).toFixed(2)
                            )}`}
                          />
                          {index < this.state.walletTransactions.length - 1 && (
                            <Line />
                          )}
                        </View>
                      )
                    }

                    return (
                      <View key={index}>
                        <AllWalletHistoryCard
                          onPress={() => this.onPress_Wallet_transaction(index)}
                          url={
                            item.transaction_type === 'transfer' &&
                            item.transaction_flow_type === 'out'
                              ? item.transfer_fromto_member_avatar_url
                              : item.transaction_type === 'transfer' &&
                                item.transaction_flow_type === 'in'
                              ? item.transfer_fromto_member_avatar_url
                              : item.product_image_url
                          }
                          title={this.renderTransactionType(item)}
                          date={item.transaction_created_datetime}
                          bet={item.product_name}
                          win={` ${
                            item.transaction_money_type == 'money'
                              ? MyData.country_code
                              : item.transaction_money_type == 'coin'
                              ? strings.t('coin')
                              : ''
                          } ${numberWithCommas(
                            Number(item.transaction_amount).toFixed(2)
                          )}`}
                        />
                        {index < this.state.walletTransactions.length - 1 && (
                          <Line />
                        )}
                      </View>
                    )
                  })}

                {this.state.selection == 2 &&
                  this.state.gameTransactions.length > 0 &&
                  this.state.gameTransactions.map((item, index) => (
                    <View key={index}>
                      <AllWalletHistoryCard
                        disabled
                        url={item.product_image_url}
                        title={
                          item.transaction_type === 'gameIn'
                            ? strings.t('enter_game')
                            : item.transaction_type === 'gameOut'
                            ? strings.t('exit_game')
                            : item.transaction_remarks
                        }
                        date={item.transaction_created_datetime}
                        bet={
                          item.transaction_type == 'gameIn' ||
                          item.transaction_type == 'gameOut'
                            ? item.product_name
                            : `${strings.t('bet')}: ${numberWithCommas(
                                Number(
                                  item.game_transaction_valid_stake
                                ).toFixed(2)
                              )}`
                        }
                        win={
                          item.transaction_type == 'gameIn' ||
                          item.transaction_type == 'gameOut'
                            ? `${MyData.country_code} ${numberWithCommas(
                                Number(item.transaction_wallet_amount).toFixed(
                                  2
                                )
                              )}`
                            : `${strings.t('win_')}: ${numberWithCommas(
                                Number(item.transaction_amount).toFixed(2)
                              )}`
                        }
                      />
                      {index < this.state.gameTransactions.length - 1 && (
                        <Line />
                      )}
                    </View>
                  ))}

                {this.state.selection == 3 &&
                  this.state.orderTransactions.length > 0 &&
                  this.state.orderTransactions.map((item, index) => (
                    <View key={index}>
                      <AllWalletHistoryCard
                        url
                        source={require('../../Resources/Images/Wallet/icon_interest.png')}
                        onPress={() => {
                          if (item.topup_id) {
                            this.onPress_TopUpHistoryDetails(index)
                          } else {
                            this.onPress_WithdrawHistoryDetails(index)
                          }
                        }}
                        title={
                          item.topup_id
                            ? strings.t('top_up')
                            : strings.t('withdraw')
                        }
                        amountColor={item.topup_id ? 'green' : 'red'}
                        date={
                          item.topup_datetime
                            ? item.topup_datetime
                            : item.transaction_created_datetime
                        }
                        bet={
                          item.topup_amount
                            ? numberWithCommas(
                                Number(item.topup_amount).toFixed(2)
                              )
                            : numberWithCommas(
                                Number(item.transaction_amount).toFixed(2)
                              )
                        }
                        win={this.onGetTopUpWithrawStatus(item)}
                        statusColor={
                          item.topup_status == 'approve'
                            ? 'green'
                            : item.withdraw_status == 'approve'
                            ? 'red'
                            : FontSecondaryColor
                        }
                      />
                      {index < this.state.orderTransactions.length - 1 && (
                        <Line />
                      )}
                    </View>
                  ))}
              </View>
            ) : (
              <ActivityIndicator
                color={SecondaryColor}
                size={'small'}
                style={{ marginTop: HeightDimen(20) }}
              />
            )}
          </ScrollView>
        </View>
        {this.state.isFetchingTopupDetails && (
          <View
            style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ActivityIndicator color={MainColor} size={'small'} />
          </View>
        )}
      </View>
    )
  }
}

const styles = {
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    flex: 1,
    height: '100%',
  },
  container: {
    flex: 1,
    // paddingTop: HeightDimen(30),
  },
  avatarContainer: {
    paddingBottom: HeightDimen(20),
    paddingHorizontal: HeightDimen(20),
  },
  avatarInnerContainer: {
    marginTop: HeightDimen(10),
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  avatarImage: {
    width: 'auto',
    height: HeightDimen(90),
    aspectRatio: 1 / 1,
    borderRadius: HeightDimen(10),
  },
  userInfoContainer: {
    marginLeft: HeightDimen(10),
  },
  qr: {
    width: 'auto',
    height: HeightDimen(25),
    aspectRatio: 1 / 1,
    tintColor: SecondaryColor,
  },
  flag: {
    width: 'auto',
    height: HeightDimen(25),
    aspectRatio: 1 / 1,
    // tintColor: "gray",
  },
  nameLabel: {
    color: FontMainColor,
    fontSize: fontDimen(18),
    fontWeight: 'bold',
    marginRight: HeightDimen(15),
  },
  vipIcon: {
    width: 'auto',
    height: HeightDimen(20),
    aspectRatio: 1 / 1,
    tintColor: SecondaryColor,
  },
  tabContainer: {
    flexDirection: 'row',
    marginTop: HeightDimen(10),
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tabLeftContainer: {
    flexDirection: 'row',
  },
  tabLeftButton: {
    width: 'auto',
    height: HeightDimen(40),
    aspectRatio: 1.5 / 1,
    borderBottomWidth: HeightDimen(3),
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabButtonLabel: {
    fontSize: fontDimen(12),
    color: FontSecondaryColor,
    textAlign: 'center',
  },
  tabRightButton: {
    marginRight: HeightDimen(10),
    flexDirection: 'row',
    alignItems: 'center',
  },
  moreButtonLogo: {
    width: 'auto',
    height: HeightDimen(15),
    aspectRatio: 1 / 1,
    resizeMode: 'contain',
    tintColor: FontSecondaryColor,
  },
  listContainer: {
    flex: 1,
    marginBottom: HeightDimen(5),
  },
  notificationContainer: {
    position: 'absolute',
    top: -4,
    right: -6,
    height: 18,
    width: 18,
    backgroundColor: 'red',
    borderRadius: 9,
    alignItems: 'center',
    justifyContent: 'center',
  },
}
export default ProfileScreen
