import React, { Component, useState, useEffect } from 'react'
import {
  Text,
  StyleSheet,
  FlatList,
  View,
  Image,
  ActivityIndicator,
  TouchableOpacity,
} from 'react-native'

import APICaller from '../../API/APICaller'
import { TextButton } from '../../Component'
import { MainFlowHeaderContainerNoFeedback } from '../../Component/Header/MainFlowHeader'
import MyToast from '../../Component/Toast/MyToast'
import MyData from '../../Data/MyData'
import storageData from '../../Data/StorageData'
import { strings } from '../../Language/Language'
import { SecondaryColor } from '../../UI/Color'
import { fontDimen, HeightDimen, WidthDimen } from '../../UI/Dimensions'

const CouponListScreen = (props) => {
  const { navigate } = props?.navigation
  const [couponsList, setCouponsList] = useState(null)
  const [item, setItem] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [activeTab, setActiveTab] = useState(0)
  const sampleTabs = [0, 1, 2, 3]
  const sampleImages = [
    // {
    //   image: require('../../Resources/Images/Coupon/coupon_1.png'),
    //   onPress: null,
    // },
    {
      image:
        storageData.selected_language == 'bm'
          ? require('../../Resources/Images/Coupon/vip-coupon-bm.jpg')
          : storageData.selected_language == 'th'
          ? require('../../Resources/Images/Coupon/vip-coupon-th.jpg')
          : storageData.selected_language == 'ch'
          ? require('../../Resources/Images/Coupon/vip-coupon-ch.jpg')
          : require('../../Resources/Images/Coupon/vip-coupon-eng.jpg'),

      onPress: (item) => navigate('CouponVip', item),
    },
    {
      image: require('../../Resources/Images/Coupon/coupon_2.png'),
      onPress: (item) => navigate('CouponEgames', item),
    },
  ]

  const onBackPressed = () => {
    if (item) {
      setItem(null)
      return
    }

    props?.route?.params?.onGoBack()
    props?.navigation?.goBack()
  }

  const backToMain = () => {
    props?.route?.params?.onBackOpenModal()
    props?.navigation?.goBack()
  }

  useEffect(() => {
    _onfetchCoupons()

    const unsubscribe = props.navigation.addListener('focus', () => {
      MyData.coupon_code = ''
    })

    return unsubscribe
  }, [])

  const _onfetchCoupons = async () => {
    await APICaller.getListOfCoupons(MyData.authToken)
      .then((res) => {
        if (res?.response?.coupons?.length > 0) {
          setCouponsList(res?.response?.coupons)
        }
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        MyToast.popToast(e.response.data.msg)
      })
  }

  const _onRedeemCoupon = async () => {
    if (!item) {
      MyToast.popToast(`Coupon code not found`)
      return
    }
    await APICaller.onRedeemCoupon(
      MyData.authToken,
      item?.coupon_code,
      item?.product_id
    )
      .then((res) => {
        // console.log('@res', res)

        if (res?.response) {
          MyData.coupon_code = item?.coupon_code
          MyToast.popToast(res?.response?.msg)
          backToMain()
        }
      })
      .catch((e) => {
        MyToast.popToast('Error: ' + e.response.data.msg)
        console.log(e.response)
      })
  }

  const _onPressRedeem = () => {
    _onRedeemCoupon()
  }

  const renderCouponItem = (item, index) => {
    return (
      <TouchableOpacity
        onPress={() => item?.onPress(couponsList[index])}
        style={{ ...styles.couponContainer, borderWidth: 0 }}
      >
        <Image
          style={{
            resizeMode: 'contain',
            height: HeightDimen(100),
            width: '100%',
          }}
          source={sampleImages[index].image}
        />
      </TouchableOpacity>
    )
    return (
      <TouchableOpacity
        onPress={() => setItem(item)}
        style={styles.couponContainer}
      >
        <Image
          style={{
            height: HeightDimen(40),
            width: 'auto',
            aspectRatio: 1 / 1,
            alignSelf: 'center',
          }}
          source={{ uri: item?.coupon_image_url }}
        />
        <View>
          <Text style={styles.titleText}>{item?.coupon_title}</Text>
          <Text style={styles.subTitleText}>{item?.coupon_terms}</Text>
        </View>
        <View>
          <Text
            style={{
              fontStyle: 'italic',
            }}
          >{`Valid until ${item?.coupon_end_datetime}`}</Text>
          {/* <Text style={styles.titleText}>{item?.coupon_title}</Text> */}
        </View>
      </TouchableOpacity>
    )
  }

  if (item) {
    return (
      <MainFlowHeaderContainerNoFeedback
        title={item?.coupon_title}
        onBackPress={() => onBackPressed()}
        bgImage={require('../../Resources/Images/Coupon/coupon_bg.png')}
      >
        <View style={styles.itemDetailsContainer}>
          <Image
            style={{
              height: HeightDimen(150),
              width: 'auto',
              aspectRatio: 1 / 1,
            }}
            source={{ uri: item?.coupon_image_url }}
          />
          <Text style={styles.titleText}>{item?.coupon_title}</Text>
          <View style={{ height: HeightDimen(12) }} />
          {/* <Text >{item?.coupon_condition}</Text> */}
          {/* <View style={{ width: '50%' }}> */}
          <View
            style={{
              marginBottom: HeightDimen(8),
              alignItems: 'center',
              width: '80%',
            }}
          >
            <Text style={styles.textLabel}>{`Coupon code: `}</Text>
            <Text style={styles.textValue}>{item?.coupon_code}</Text>
          </View>
          <View
            style={{
              marginBottom: HeightDimen(8),
              alignItems: 'center',
              width: '80%',
            }}
          >
            <Text style={styles.textLabel}>{`Coupon type: `}</Text>
            <Text style={styles.textValue}>{item?.coupon_type}</Text>
          </View>
          <View
            style={{
              marginBottom: HeightDimen(8),
              alignItems: 'center',
              width: '80%',
            }}
          >
            <Text style={styles.textLabel}>{`Valid Until: `}</Text>
            <Text>{item?.coupon_end_datetime}</Text>
          </View>

          {/* </View> */}
          <View style={{ position: 'absolute', bottom: 40, width: '90%' }}>
            <TextButton
              label={strings.t('redeem_coupon')}
              size={5}
              onPress={() => _onPressRedeem()}
              isLoading={isLoading}
            />
          </View>
        </View>
      </MainFlowHeaderContainerNoFeedback>
    )
  }

  const renderTab = (index) => {
    return (
      <TouchableOpacity
        onPress={() => setActiveTab(index)}
        style={activeTab == index ? styles.activeTab : styles.inActiveTab}
      >
        <Text style={styles.textValue}>{`Tab ${index + 1}`}</Text>
      </TouchableOpacity>
    )
  }

  const renderLoading = () => {
    return (
      <View
        style={{
          height: '40%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ActivityIndicator color={SecondaryColor} />
      </View>
    )
  }

  return (
    <MainFlowHeaderContainerNoFeedback
      title={strings.t('promotion')}
      onBackPress={() => onBackPressed()}
      bgImage={require('../../Resources/Images/Coupon/coupon_bg.png')}
    >
      <View style={styles.container}>
        <FlatList
          contentContainerStyle={{
            flexGrow: 1,
          }}
          ListHeaderComponent={() => (
            <TouchableOpacity
              style={styles.couponContainer}
              onPress={() => navigate('CouponVip')}
            >
              <Image
                style={{
                  resizeMode: 'stretch',
                  height: '100%',
                  width: '100%',
                }}
                source={sampleImages[0]?.image}
              />
            </TouchableOpacity>
          )}
          data={couponsList}
          renderItem={({ item }) => {
            return (
              <TouchableOpacity
                style={styles.couponContainer}
                onPress={() => navigate('BannerDetail', item)}
              >
                <Image
                  style={{
                    height: '100%',
                    width: '100%',
                    resizeMode: 'stretch',
                  }}
                  source={{
                    uri: item?.coupon_image_url,
                  }}
                />
              </TouchableOpacity>
            )
          }}
          keyExtractor={(item, index) => String(index)}
        />
        {isLoading && renderLoading()}
      </View>
    </MainFlowHeaderContainerNoFeedback>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: HeightDimen(8),
  },
  couponContainer: {
    width: '100%',
    height: HeightDimen(150),
    marginBottom: HeightDimen(10),
  },
  itemDetailsContainer: {
    padding: HeightDimen(20),
    flex: 1,
    alignItems: 'center',
  },
  titleText: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: fontDimen(20),
  },
  subTitleText: {
    color: 'white',
    fontSize: fontDimen(16),
  },
  textLabel: {
    color: 'white',
    fontWeight: '500',
    marginBottom: 8,
  },
  textValue: {
    color: 'white',
    fontWeight: 'bold',
  },
  activeTab: {
    backgroundColor: `rgb(122, 122, 122)`,
    height: HeightDimen(30),
    width: WidthDimen(80),
    alignItems: 'center',
    justifyContent: 'center',
  },
  inActiveTab: {
    backgroundColor: `rgb(79, 79, 79)`,
    height: HeightDimen(30),
    width: WidthDimen(80),
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default CouponListScreen
